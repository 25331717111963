import { 
    ADDED_USER, 
    EDITED_USER,
    SET_USER,  
    SET_ADMINS,
    SET_AUTHORS, 
    SET_EDITORS,
    SET_USER_MSG, 
    DELETED_USER,
    TOGGLE_ADMIN,
    TOGGLE_DISABLED_USER
} from '../actions/types';

import { ADMIN, AUTHOR, EDITOR } from '../utils/constants';

const initialState = {
    msg: null,
    pagination: {},
    user: {},
    posts: [],
    admins: [],
    authors: [],
    editors: []
};

const usersReducer = (state = initialState, action) => {
    let admins = [];
    let authors  = [];
    // let editors = [];
    let user = {};
    let userIndex;

    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload
            };

        case ADDED_USER: 
            user = action.payload.user;

            if (user.role === AUTHOR) {
                return {
                    ...state,
    
                    authors: [user, ...state.authors],
                    msg: action.payload.msg
                }
            } 
            if (user.role === EDITOR) {
                return {
                    ...state,
    
                    editors: [user, ...state.editors],
                    msg: action.payload.msg
                }
            } 
            
            return {
                ...state,

                admins: [user, ...state.admins],
                msg: action.payload.msg
            }
        
        case SET_ADMINS:
            return {
                ...state,
                admins: action.payload
            };

        case SET_AUTHORS:
            return {
                ...state,
                authors: action.payload
            };

        case SET_EDITORS:
            return {
                ...state,
                editors: action.payload
            };

        case TOGGLE_ADMIN:
            const role = action.payload.role
            if (role === ADMIN) {
                // Remove user from authors and add udated user to admins
                authors = state.authors;
                userIndex = authors.findIndex(user => user._id === action.payload.user._id);
                authors.splice(userIndex, 1);

                return {
                    ...state,
                    authors: [...authors],
                    admins: [action.payload.user, ...state.admins],
                    msg: action.payload.msg
                };
            }
            
            // Remove user from admins and add udated user to authors
            admins = state.admins;
            userIndex = admins.findIndex(user => user._id === action.payload.user._id);
            admins.splice(userIndex, 1);

            return {
                ...state,
                authors: [action.payload.user, ...authors],
                admins: [...state.admins],
                msg: action.payload.msg
            };

        case DELETED_USER:
            authors = state.authors;
            userIndex = authors.findIndex(user => user._id === action.payload.userId);
            authors.splice(userIndex, 1);

            return {
                ...state,
                authors: [...authors],
                user: {},
                msg: action.payload.msg
            };

        case EDITED_USER:
            authors = state.authors;
            userIndex = authors.findIndex(user => user._id === action.payload.user._id);
            authors.splice(userIndex, 1, action.payload.user);

            return {
                ...state,
                authors: [...authors],
                user: action.payload.user,
                msg: action.payload.msg
            };

        case TOGGLE_DISABLED_USER:
            user = action.payload.user;
            authors = state.authors;
            userIndex = authors.findIndex(item => item._id === user._id);
            authors.splice(userIndex, 1, user);
            
            return {
                ...state,
                authors: [...state.authors],
                msg: action.payload.msg
            };

        case SET_USER_MSG:
            return {
                ...state,
                msg: action.payload
            };

        default:
            return state;
    }
};

export default usersReducer;