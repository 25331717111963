import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { 
    Avatar,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Account, Camera, EyeOutline, EyeOffOutline } from 'mdi-material-ui';

import { GET_ERRORS, SET_AUTH_MSG } from '../../../actions/types';
import { changePassword, deleteAvatar, updateProfile, uploadAvatar } from '../../../actions/auth';
import { COLORS, FILE_LIMIT } from '../../../utils/constants';
import isEmpty from '../../../utils/isEmpty';

import validateUpdateProfile from '../../../utils/validation/auth/update';
import validateChangePassword from '../../../utils/validation/auth/changePassword';

import Spinner from '../../../components/common/Spinner';
import Toast from '../../../components/common/Toast';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: theme.spacing(3),
        padding: theme.spacing(3),

        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
        },

        '& form': {
            border: `1px solid ${COLORS.borderColor}`,
            padding: theme.spacing(2),

            '& h5': {
                fontWeight: 500,
                marginBottom: theme.spacing(2),
                textAlign: 'center'
            }
        }
    },

    avatar: {
        height: '250px',
        width: '250px',

        [theme.breakpoints.down('md')]: {
            height: '150px',
            width: '150px',
        },
        // height: `${theme.spacing(5)} !important`,
        // width: `${theme.spacing(5)} !important`
    },

    avatarContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },

    avatarButtonContainer: {
        // border: '1px solid red',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        width: '100%'
    },

    selectImageButton: {
        backgroundColor: theme.palette.primary.main,
        color: COLORS.offWhite,
        position: 'relative',
        top: theme.spacing(-10),
        zIndex: 1,

        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: COLORS.offWhite
        }
    },

    removeAvatarButton: {
        position: 'relative',
        top: 0,
        zIndex: 1
    }
}));

const Index = (props) => {
	const classes = useStyles();
    const dispatch = useDispatch();

    const errorsState = useSelector(state => state.errors);
    const { msg, user } = useSelector(state => state.auth);

    const { handleSetHeading, handleSetTitle, changePassword, updateProfile, uploadAvatar, deleteAvatar } = props;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [bio, setBio] = useState('');

    const [bioLength, setBioLength] = useState(0);

    const [image, setImage] = useState(null);
    const [imageSrc, setImageSrc] = useState('');

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const [loadingText, setLoadingText] = useState('');
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({});

    const toast = useRef();
    const selectImageInput = useRef();


	useEffect(() => {
		handleSetTitle('User Profile');
        handleSetHeading('Manage User Profile');
        setProfile();
		// eslint-disable-next-line
	}, []);

    useEffect(() => {
        if (toastMessage) {
            toast.current.handleClick();
        }
    }, [dispatch, msg, toastMessage]);

    useEffect(() => {
        if (!_.isEmpty(errorsState)) {
            setErrors(errorsState);
        }
    }, [errorsState]);

    useEffect(() => {
        if (!isEmpty(errors.msg)) {
            setLoading(false);
            setLoadingText('');
            setToastType('error');
            setToastTitle('ERROR');
            setToastMessage(errors.msg);
        }
    }, [errors]);

    const setProfile = () => {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);
        setUsername(user.username);
        setBio(user.bio);

        setImageSrc(user.avatar);
    };

    useEffect(() => {
        if (msg) {
            setToastType('success');
            setToastTitle('SUCCESS');
            setToastMessage(msg);
            setLoading(false);
            setPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setTimeout(() => {
                dispatch({
                    type: SET_AUTH_MSG,
                    payload: null
                });
            }, 5000);
        }
    }, [dispatch, msg]);

    useEffect(() => {
        setBioLength(bio?.length || 0);
    }, [bio, bio?.length]);

    useEffect(() => {
        if (!user.avatar) {
            setImage(null);
            setImageSrc('');
        }
    }, [user.avatar]);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSelectImageButtonClick = () => {
        selectImageInput.current.click()
    };

    const handleSetImage = (e) => {
        setImage(e.target.files[0]);
        const reader = new FileReader();

        reader.onload = (() => {
            const image = reader.result; //Array Buffer
            setImageSrc(image);
        });
        reader.readAsDataURL(e.target.files[0]);
    };

    const handleUpdateProfile = (e) => {
        e.preventDefault();
        setErrors({});

        const data = {
            firstName,
            lastName,
            email,
            username,
            bio
        };

        const { errors, isValid } = validateUpdateProfile(data);

        if (!isValid) {
            return setErrors({ msg: 'Invalid profile data', ...errors });
        }

        setErrors({});
        dispatch({
            type: GET_ERRORS,
            payload: {}
        });
        
        setLoadingText('Updating Profile . . . ');
        setLoading(true);
        updateProfile(data);
    };

    const handleUploadAvatar = (e) => {
        e.preventDefault();
        
        setErrors({});

        if (!image) {
            return setErrors({ msg: 'Image is required!' });
        }

        if ((image.size / 1024) > FILE_LIMIT) {
            return setErrors({ msg: 'File too large. Limit is 10MB!' });
        }

        let data = new FormData();
        data.append('image', image);

        setErrors({});
        dispatch({
            type: GET_ERRORS,
            payload: {}
        });
        
        setLoadingText('Uploading Avatar . . . ');
        setLoading(true);
        uploadAvatar(data);
    };

    const handleChangePassword = (e) => {
        e.preventDefault();
        setErrors({});
        dispatch({
            type: GET_ERRORS,
            payload: {}
        });

        const data = {
            password,
            newPassword,
            confirmPassword
        };

        const { errors, isValid } = validateChangePassword(data);

        if (!isValid) {
            return setErrors({ msg: 'Invalid password data', ...errors });
        }
        
        setLoadingText('Changing Password . . . ');
        setLoading(true);
        changePassword(data);
    };

    const handleDeleteAvatar = () => {
        setLoadingText('Removing Avatar . . .');
        setLoading(true);
        deleteAvatar();
    };

	return (
        <>
            <Toast 
                ref={toast}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            {loading && <Spinner text={loadingText}  />}
            <section className={classes.root}>
                <form onSubmit={handleUpdateProfile} noValidate>
                    <Typography variant="h5">Change Password</Typography>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="First Name"
                                placeholder="Enter Your First Name"
                                helperText={errors.firstName}
                                fullWidth
                                required
                                error={errors.firstName ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="Last Name"
                                placeholder="Enter Your Last Name"
                                helperText={errors.lastName}
                                fullWidth
                                required
                                error={errors.lastName ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="Email"
                                placeholder="Enter Your Email Address"
                                helperText={errors.email || 'info@example.com'}
                                fullWidth
                                required
                                error={errors.email ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="Username"
                                placeholder="Enter Your Username"
                                helperText={errors.username}
                                fullWidth
                                required
                                error={errors.username ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                className={classes.input}
                                value={bio}
                                onChange={(e) => setBio(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="Bio"
                                placeholder="Enter Your Bio"
                                helperText={errors.bio || `150 characters max. ${bioLength}/250`}
                                fullWidth
                                required
                                multiline
                                minRows={3}
                                maxRows={3}
                                error={errors.bio ? true : false}
                                inputProps={{
                                    maxLength: 150
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                className={classes.button}
                                variant="contained" 
                                color="primary"
                                type="submit"
                                onClick={handleUpdateProfile}
                            >
                                Update Profile
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <form onSubmit={handleUploadAvatar} noValidate className={classes.avatarContainer}>
                    <Typography variant="h5">Avatar</Typography>
                    <input
                        accept="image/*"
                        className={classes.input}
                        style={{ display: 'none' }}
                        id="select-image-button"
                        onChange={handleSetImage}
                        ref={selectImageInput}
                        type="file"
                    />
                    {imageSrc ? 
                        <Avatar alt={user.username} src={imageSrc} classes={{ root: classes.avatar }} />
                        :
                        <Avatar classes={{ root: classes.avatar }} >
                            <Account className={classes.avatar} classes={{ root: classes.avatar }} />
                        </Avatar>
                    }
                    {/* <label htmlFor="select-image-button"> */}
                        <Tooltip title="Select Image" arrow>
                            <IconButton className={classes.selectImageButton} onClick={handleSelectImageButtonClick}>
                                <Camera />
                            </IconButton>
                        </Tooltip>
                    {/* </label>  */}
                    <div className={classes.avatarButtonContainer}>
                        {image && 
                            <Button 
                                className={classes.button}
                                variant="contained" 
                                color="primary"
                                type="submit"
                                onClick={handleUploadAvatar}
                            >
                                Upload
                            </Button>
                        }
                        {imageSrc &&
                            <Button variant="outlined" component="span" className={classes.removeAvatarButton} color="primary" onClick={handleDeleteAvatar}>
                                Remove Avatar
                            </Button>
                        }
                    </div>
                </form>
                <form onSubmit={handleChangePassword} noValidate>
                    <Typography variant="h5">Change Password</Typography>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <TextField 
                                className={classes.input}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                variant="outlined" 
                                label="Current Password"
                                placeholder="Enter Your Current Password"
                                helperText={errors.password}
                                fullWidth
                                required
                                error={errors.password ? true : false}
                                inputProps={{
                                    endadornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowPassword}
                                            >
                                                {showPassword ? 
                                                    <Tooltip title="Hide password" placement="bottom" arrow>
                                                        <EyeOffOutline />
                                                    </Tooltip>
                                                        : 
                                                    <Tooltip title="Show password" placement="bottom" arrow>
                                                        <EyeOutline />
                                                    </Tooltip>
                                                    }
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                className={classes.input}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                type={showPassword ? 'text': 'password'}
                                variant="outlined" 
                                label="New Password"
                                placeholder="Enter New Password"
                                helperText={errors.newPassword}
                                fullWidth
                                required
                                error={errors.newPassword ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowNewPassword}
                                            >
                                                {showNewPassword ? 
                                                    <Tooltip title="Hide password" placement="bottom" arrow>
                                                        <EyeOffOutline />
                                                    </Tooltip>
                                                        : 
                                                    <Tooltip title="Show password" placement="bottom" arrow>
                                                        <EyeOutline />
                                                    </Tooltip>
                                                    }
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                className={classes.input}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type={showConfirmPassword ? 'text': 'password'}
                                variant="outlined" 
                                label="Confirm New Password"
                                placeholder="Confirm New Password"
                                helperText={errors.confirmPassword}
                                fullWidth
                                required
                                error={errors.confirmPassword ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowConfirmPassword}
                                            >
                                                {showConfirmPassword ? 
                                                    <Tooltip title="Hide password" placement="bottom" arrow>
                                                        <EyeOffOutline />
                                                    </Tooltip>
                                                        : 
                                                    <Tooltip title="Show password" placement="bottom" arrow>
                                                        <EyeOutline />
                                                    </Tooltip>
                                                    }
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                className={classes.button}
                                variant="contained" 
                                color="primary"
                                type="submit"
                                onClick={handleChangePassword}
                            >
                                Change Password
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </section>
        </>
	);
}

Index.propTypes = {
    changePassword: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired,
    uploadAvatar: PropTypes.func.isRequired,
    deleteAvatar: PropTypes.func.isRequired
};

export default connect(undefined, { changePassword, updateProfile, uploadAvatar, deleteAvatar })(Index);