import { useEffect, useRef, useState } from 'react';
import { connect, batch, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';

import { GET_ERRORS, SET_USER_MSG } from '../../../actions/types';
import { toggleAdmin } from '../../../actions/auth';

import Toast from '../../../components/common/Toast';

const columns = [
    { id: 'S/N', label: 'S/N', minWidth: 10 },
    { id: 'name', label: 'Name', minWidth: 170 },
    {
      id: 'email',
      label: 'Email',
      minWidth: 170,
    //   align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'username',
      label: 'Username',
      minWidth: 90,
    //   align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'lastSeen',
      label: 'Last Seen',
      minWidth: 100,
    //   align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    }
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',    
    },

    container: {
        maxHeight: 440,
    },

    tableHeader: {
        fontWeight: 600
    },

    pagination: {
        display: 'flex'
    }
}));

const Admins = ({ toggleAdmin }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { admins, msg } = useSelector(state => state.users);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const toast = useRef();

    useEffect(() => {
        return () => {
            batch(() => {
                dispatch({
                    type: GET_ERRORS,
                    payload: {}
                });
                dispatch({
                    type: SET_USER_MSG,
                    payload: null
                });
            });
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (msg) {
            setToastType('success');
            setToastTitle('SUCCESS');
            setToastMessage(msg);
            // setLoading(false);
        }
    }, [msg]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <Toast 
                ref={toast}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            <Paper>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        className={classes.tableHeader}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {admins.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((admin, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={admin._id}>
                                        <TableCell>{index + 1}.</TableCell>
                                        <TableCell>{`${admin.firstName} ${admin.lastName}`}</TableCell>
                                        <TableCell>{admin.email}</TableCell>
                                        <TableCell>{admin.username}</TableCell>
                                        <TableCell>{moment(admin.createdAt).format('MMM Do, YYYY')}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={admins.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        root: classes.pagination
                    }}
                />
            </Paper>
        </>
    );
};

Admins.propTypes = {
    toggleAdmin: PropTypes.func.isRequired
};

export default connect(undefined, { toggleAdmin })(Admins);