import Validator from 'validator';
import isEmpty from '../../isEmpty';

const update = (data) => {
    let errors = {};
    data.firstName = !isEmpty(data.firstName) ?  data.firstName : '';
    data.lastName = !isEmpty(data.lastName) ?  data.lastName : '';
    data.email = !isEmpty(data.email) ?  data.email : '';
    data.username = !isEmpty(data.username) ?  data.username : '';
    data.bio = !isEmpty(data.bio) ?  data.bio : '';

    if (Validator.isMobilePhone(data.firstName)) {
        errors.firstName = 'Last name cannot be a phone number!';
    }
    if (Validator.isNumeric(data.firstName)) {
        errors.firstName = 'First name cannot be a number!';
    }
    if (Validator.isEmail(data.firstName)) {
        errors.firstName = 'First name cannot be an email!';
    }
    if (Validator.isEmpty(data.firstName)) {
        errors.firstName = 'First name is required!';
    }

    if (Validator.isMobilePhone(data.lastName)) {
        errors.lastName = 'Last name cannot be a phone number!';
    }
    if (Validator.isNumeric(data.lastName)) {
        errors.lastName = 'Last name cannot be a number!';
    }
    if (Validator.isEmail(data.lastName)) {
        errors.lastName = 'Last name cannot be an email!';
    }
    if (Validator.isEmpty(data.lastName)) {
        errors.lastName = 'Last name is required!';
    }
    
    if (Validator.isEmpty(data.email)) {
        errors.email = 'Email Address is required!';
    }
    if (!Validator.isEmail(data.email)) {
        errors.email = 'Invalid Email Address!';
    }

    if (Validator.isEmpty(data.username)) {
        errors.username = 'Username is required!';
    }

    if (!Validator.isEmpty(data.bio)) {
        if (data.bio.length > 150) {
            errors.bio = 'Bio cannot be more than 150 characters';
        }
    }
    
    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export default update;