import { connect } from 'react-redux';
import {  useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ authenticated, children }) => {
    const location = useLocation();

    return authenticated ? children : <Navigate to="/" state={{ from: location }} replace />
}

PrivateRoute.propTypes = {
    authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated
});

export default connect(mapStateToProps)(PrivateRoute);