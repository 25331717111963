import Validator  from'validator';
import isEmpty from'../../isEmpty';

const createPodcast = (data) => {
    let errors = {};
    data.description = !isEmpty(data.description) ?  data.description : '';
    data.title = !isEmpty(data.title) ?  data.title : '';
    data.url = !isEmpty(data.url) ?  data.url : '';

    if (Validator.isEmpty(data.description)) {
        errors.description = 'Podcast description is required!';
    }

    if (Validator.isEmpty(data.title)) {
        errors.title = 'Podcast title is required!';
    }

    if (!Validator.isURL(data.url)) {
        errors.url = 'Invalid URL!';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export default createPodcast;