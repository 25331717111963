import axios from 'axios';
import { batch } from 'react-redux';

import handleError from '../utils/handleError';
import { 
    ADDED_POST, 
    EDITED_POST,
    SET_POST, 
    SET_POSTS, 
    SET_MORE_POSTS, 
    SET_PENDING_POSTS,
    SET_DRAFT_POSTS, 
    SET_APPROVED_POSTS,
    PUBLISHED_POST,
    MOVE_TO_DRAFTS,
    APPROVED_POST,
    DELETED_POST
} from './types';

import { SERVER_URL } from '../utils/constants';

const api = process.env.NODE_ENV === 'production' ? '/posts' : `${SERVER_URL}/posts`;

export const createPost = (post) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'post',
            url: api,
            data: post,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        const { data, msg } = res.data;
        return dispatch({
            type: ADDED_POST,
            payload: { post: data, msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const editPost = (post, id) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'put',
            url: `${api}/${id}`,
            data: post,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        const { data, msg } = res.data;
        return dispatch({
            type: EDITED_POST,
            payload: { post: data, msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const approvePost = (id) => async (dispatch) => {
    try {
        const res = await axios.put(`${api}/approve/${id}`);
        const {  msg } = res.data;
        return dispatch({
            type: APPROVED_POST,
            payload: { postId: id, msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const publishPost = (id) => async (dispatch) => {
    try {
        const res = await axios.put(`${api}/togglePublished/${id}`);
        const {  msg } = res.data;
        return dispatch({
            type: PUBLISHED_POST,
            payload: { postId: id, msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const moveToDrafts = (id, currentState) => async (dispatch) => {
    try {
        const res = await axios.put(`${api}/togglePublished/${id}`);
        const {  msg } = res.data;
        return dispatch({
            type: MOVE_TO_DRAFTS,
            payload: { postId: id, currentState, msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const getAllPosts = () => async (dispatch) => {
    try {
        const res = await axios.get(`${api}/all`);
        const { drafts, approved, pending } = res.data.data;
        return batch(() => {
            dispatch({
                type: SET_DRAFT_POSTS,
                payload: drafts
            });
            dispatch({
                type: SET_APPROVED_POSTS,
                payload: approved
            });
            dispatch({
                type: SET_PENDING_POSTS,
                payload: pending
            });
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const getPosts = ({ page, limit }) => async (dispatch) => {
    try {
        const res = await axios.get(`${api}?page=${page}&limit=${limit}`);
        const { data, pagination } = res.data;
        return dispatch({
            type: SET_POSTS,
            payload: { posts: data, pagination }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const getMorePosts = ({ page, limit }) => async (dispatch) => {
    try {
        const res = await axios.get(`${api}?page=${page}&limit=${limit}`);
        const { data, pagination } = res.data;
        return dispatch({
            type: SET_MORE_POSTS,
            payload: { posts: data, pagination }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const getPost = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`${api}/${id}`);
        return dispatch ({
            type: SET_POST,
            payload: res.data.data
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const getPostBySlug = (slug) => async (dispatch) => {
    try {
        const res = await axios.get(`${api}/slug/${slug}`);
        return dispatch ({
            type: SET_POST,
            payload: res.data.data
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const getOldPostBySlug = ({year, month, day, slug}) => async (dispatch) => {
    try {
        const res = await axios.get(`${api}/old/${year}/${month}/${day}/${slug}`);
        return dispatch ({
            type: SET_POST,
            payload: res.data.data
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const deletePost = (id, currentState) => async (dispatch) => {
    try {
        const res = await axios.delete(`${api}/${id}`);
        const { msg } = res.data.data;
        return dispatch ({
            type: DELETED_POST,
            payload: { id, currentState, msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};