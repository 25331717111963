import { useEffect, useRef, useState } from 'react';
import { useNavigate} from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { makeStyles } from '@material-ui/core/styles';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip
} from '@material-ui/core';
import { ContentCopy, FileDocumentEdit, Delete } from 'mdi-material-ui';

import { SET_PODCAST } from '../../../actions/types';
import { deletePodcast, getPodcasts } from '../../../actions/podcasts';
import { ADMIN } from '../../../utils/constants';
import { DASHBOARD, EDIT_PODCAST } from '../../../routes';

import Toast from '../../../components/common/Toast';

const columns = [
    { id: 'S/N', label: 'S/N', minWidth: 10 },
    { id: 'title', label: 'Title', minWidth: 100 },
    { id: 'url', label: 'URL', minWidth: 100 },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 150,
      format: (value) => value.toLocaleString('en-US'),
    }
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',    
    },

    container: {
        maxHeight: 440,
    },

    tableHeader: {
        fontWeight: 600
    },

    pagination: {
        display: 'flex'
    }
}));

const Podcasts = ({ getPodcasts, deletePodcast }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { msg, podcasts } = useSelector(state => state.podcasts);
    const { user } = useSelector(state => state.auth);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const toastComponent = useRef();

    useEffect(() => {
        if (podcasts.length === 0) {
            getPodcasts();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (msg) {
            setToastType('success');
            setToastTitle('SUCCESS');
            setToastMessage(msg);
            // setLoading(false);
        }
    }, [msg]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleEditPodcast = (podcast) => {
        dispatch({
            type: SET_PODCAST,
            payload: podcast
        });
        navigate(`${DASHBOARD}${EDIT_PODCAST}`);
    };

    const handleDeletePodcast = (podcastId) => {
        const confirmDelete = window.confirm('Delete Podcast?');

        if (confirmDelete) {
            deletePodcast(podcastId);
        }
    };

    const handleLinkCopy = async (url) => {
        await navigator.clipboard.writeText(url);
        toast.success('Link copied!', {
            duration: 4000,
            position: 'top-center',
            style: {},
            className: '',
            icon: '👏',
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },

            ariaProps: {
                role: 'status',
                'aria-live': 'polite',
            }
        });
    };

    return (
        <>
            <Toast 
                ref={toastComponent}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            <Paper>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        className={classes.tableHeader}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {podcasts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((podcast, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={podcast._id}>
                                        <TableCell>{index + 1}.</TableCell>
                                        <TableCell>{podcast.title}</TableCell>
                                        <TableCell>{podcast.url}</TableCell>
                                        {/* <TableCell>{podcast.author}</TableCell> */}
                                        {/* <TableCell>{moment(podcast.createdAt).format('MMM Do, YYYY')}</TableCell> */}
                                        <TableCell>
                                            <IconButton 
                                                onClick={() => handleLinkCopy(podcast.url)}
                                            >
                                                <Tooltip arrow title="Copy URL"
                                                >
                                                    <ContentCopy />
                                                </Tooltip>
                                            </IconButton>
                                            {(user.role === ADMIN || user.id === podcast.author) && 
                                                <IconButton onClick={() => handleEditPodcast(podcast)}>
                                                    <Tooltip arrow title="Edit Podcast">
                                                        <FileDocumentEdit />
                                                    </Tooltip>
                                                </IconButton>
                                            }
                                            {(user.role === ADMIN || user.id === podcast.author) && 
                                                <IconButton onClick={() => handleDeletePodcast(podcast._id)}>
                                                    <Tooltip arrow title="Delete Podcast"
                                                        disabled={!podcast.author === user._id || !user.role === ADMIN ? true : false}
                                                    >
                                                        <Delete />
                                                    </Tooltip>
                                                </IconButton>
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={podcasts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        root: classes.pagination
                    }}
                />
            </Paper>
        </>
    );
};

Podcasts.propTypes = {
    deletePodcast: PropTypes.func.isRequired,
    getPodcasts: PropTypes.func.isRequired
};

export default connect(undefined, { deletePodcast, getPodcasts })(Podcasts);