import Validator from 'validator';
import isEmpty from '../../isEmpty';

const changePassword = (data) => {
    let errors = {};
    data.password = !isEmpty(data.password) ?  data.password : '';
    data.newPassword = !isEmpty(data.newPassword) ?  data.newPassword : '';
    data.confirmPassword = !isEmpty(data.confirmPassword) ?  data.confirmPassword : '';

    if (Validator.isEmpty(data.password)) {
        errors.password = 'Your current password is required!';
    }

    if (!Validator.isLength(data.newPassword, { min: 8 })) {
        errors.newPassword = 'Your password should be at least 8 characters long!';
    }

    if (Validator.isEmpty(data.newPassword)) {
        errors.newPassword = 'Your new password is required!';
    }

    if (!Validator.equals(data.newPassword, data.confirmPassword)) {
        errors.newPassword = 'Passwords do not match!';
        errors.confirmPassword = 'Passwords do not match!';
    }
    if (Validator.isEmpty(data.confirmPassword)) {
        errors.confirmPassword = 'Please confirm your password!';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export default changePassword;