import { 
    ADDED_POST, 
    EDITED_POST,
    SET_POST, 
    SET_POSTS, 
    SET_MORE_POSTS, 
    SET_PENDING_POSTS,
    SET_DRAFT_POSTS, 
    SET_APPROVED_POSTS,
    SET_POST_MSG, 
    PUBLISHED_POST,
    MOVE_TO_DRAFTS,
    APPROVED_POST,
    DELETED_POST
} from '../actions/types';

import { APPROVED, DRAFT, PENDING } from '../utils/constants';

const initialState = {
    msg: null,
    pagination: {},
    post: {},
    posts: [],
    pending: [],
    approved: [],
    drafts: [],
    all: []
};

const postsReducer = (state = initialState, action) => {
    let pending = [];
    let approved  = [];
    let drafts = [];
    let post = {};
    let postIndex;

    switch (action.type) {
        case SET_POST:
            return {
                ...state,
                post: action.payload
            };

        case SET_POSTS:
            return {
                ...state,
                pagination: action.payload.pagination,
                posts: action.payload.posts
            };

        case SET_MORE_POSTS:
            return {
                ...state,
                pagination: action.payload.pagination,
                posts: state.posts.concat(action.payload.posts)
            };

        case ADDED_POST: 
            return {
                ...state,
                // post: action.payload.post,
                drafts: [action.payload.post, ...state.drafts],
                msg: action.payload.msg
            }

        case EDITED_POST: 
            drafts = state.drafts;
            drafts.splice(drafts.findIndex(item => item._id === action.payload.post._id), 1, action.payload.post);

            return {
                ...state,
                post: action.payload.post,
                drafts,
                msg: action.payload.msg
            }

        case SET_PENDING_POSTS:
            return {
                ...state,
                pending: action.payload
            };

        case SET_DRAFT_POSTS:
            return {
                ...state,
                drafts: action.payload
            };

        case SET_APPROVED_POSTS:
            return {
                ...state,
                approved: action.payload
            };

        case APPROVED_POST:
            postIndex = state.pending.findIndex(item => item._id === action.payload.postId);
            pending = state.pending;
            post = pending[postIndex];
            pending.splice(postIndex, 1);

            return {
                ...state,
                pending: [...pending],
                approved: [post, ...state.approved],
                msg: action.payload.msg
            };

        case PUBLISHED_POST:
            postIndex = state.drafts.findIndex(item => item._id === action.payload.postId);
            drafts = state.drafts;
            post = drafts[postIndex];
            drafts.splice(postIndex, 1);

            return {
                ...state,
                drafts,
                pending: [post, ...state.pending],
                msg: action.payload.msg
            };

        case MOVE_TO_DRAFTS:
            postIndex = action.payload.currentState === APPROVED ? state.approved.findIndex(item => item._id === action.payload.postId) :  state.pending.findIndex(item => item._id === action.payload.postId);
            pending = state.pending;
            approved = state.approved;
            post = action.payload.currentState === APPROVED ? approved[postIndex] : pending[postIndex];
            approved.splice(postIndex, 1);
            pending.splice(postIndex, 1);

            return {
                ...state,
                pending: [...pending],
                approved: [...approved],
                drafts: [post, ...state.drafts],
                msg: action.payload.msg
            };

        case SET_POST_MSG:
            return {
                ...state,
                msg: action.payload
            };

        case DELETED_POST:
            drafts = state.drafts;
            pending = state.pending;
            approved = state.approved;

            if (action.payload.currentState === DRAFT) {
                drafts.splice(drafts.findIndex(item => item._id === action.payload.id), 1);
            }

            if (action.payload.currentState === PENDING) {
                pending.splice(pending.findIndex(item => item._id === action.payload.id), 1);
            }

            if (action.payload.currentState === APPROVED) {
                approved.splice(approved.findIndex(item => item._id === action.payload.id), 1);
            }

            return {
                ...state,
                drafts: [...state.drafts],
                pending: [...state.pending],
                approved: [...state.approved],
                msg: action.payload.msg
            };

        default:
            return state;
    }
};

export default postsReducer;