import Validator  from'validator';
import isEmpty from'../../isEmpty';

const createPost = (data) => {
    let errors = {};
    data.title = !isEmpty(data.title) ?  data.title : '';
    data.slug = !isEmpty(data.slug) ?  data.slug : '';
    data.body = !isEmpty(data.body) ?  data.body : '';

    if (Validator.isEmpty(data.title)) {
        errors.title = 'Post title is required!';
    }

    if (data.slug.trim().split('-').length > 4) {
        errors.slug = 'Slug can only contain 4 words separated by dashed (-)';
    }
    if (data.slug.length > 30) {
        errors.slug = 'Slug should not contain more than 30 characters and should not contain any spaces.';
    }
    if (Validator.isEmpty(data.slug)) {
        errors.slug = 'Slug is required';
    }

    if (Validator.isEmpty(data.body)) {
        errors.body = 'Post body is required!';
    }

    if (data.body.replace(/(<([^>]+)>)/gi, '') === '') {
        errors.body = 'Post body is required!';
    }
    
    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export default createPost;