import Validator from 'validator';
import isEmpty from '../../isEmpty';

const login = (data) => {
    let errors = {};
    data.email = !isEmpty(data.email) ?  data.email : '';
    data.password = !isEmpty(data.password) ?  data.password : '';

    if (Validator.isEmpty(data.email)) {
        errors.email = 'Email Address is required!';
    }
    if (!Validator.isEmail(data.email)) {
        errors.email = 'Invalid Email Address!';
    }

    if (Validator.isEmpty(data.password)) {
        errors.password = 'Password is required';
    }
   
    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export default login;