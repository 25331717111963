import { useEffect, useRef, useState } from 'react';
import { connect, batch, useDispatch, useSelector } from 'react-redux';
import { Button, Grid, TextField, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Spinner from '../../../components/common/Spinner';
import TextEditor from '../../../components/common/TextEditor';
import Toast from '../../../components/common/Toast';
import validateEditPost from '../../../utils/validation/posts/edit';

import { GET_ERRORS, SET_POST_MSG } from '../../../actions/types';
import { editPost } from '../../../actions/posts';
import isEmpty from '../../../utils/isEmpty';
import { FILE_LIMIT } from '../../../utils/constants';


const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
        overflow: 'hidden',
        // height: '100%',
        paddingBottom: theme.spacing(5),

        '& form': {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),

            '& img': {
                width: '100%'
            }
        },
    },

    textEditor: {
        height: theme.spacing(30)
    },

    postError: {
        color: '#ff0000',
        display: 'block',
        marginTop: theme.spacing(9)
    },

    uploadButton: {
        marginTop: theme.spacing(7)
    },

    button: {

    }
}));

const EditPost = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();

    const errorsState = useSelector(state => state.errors);
    const { msg, post } = useSelector(state => state.posts);

    const [title, setTitle] = useState(post.title);
    const [body, setBody] = useState(post.body);
    const [image, setImage] = useState(null);
    const [imageSrc, setImageSrc] = useState(post.imageUrl);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const { handleSetHeading, handleSetTitle } = props;

    const toast = useRef();

	useEffect(() => {
		handleSetTitle('Edit Post');
		handleSetHeading('Edit Post');

        return () => {
            batch(() => {
                dispatch({
                    type: GET_ERRORS,
                    payload: {}
                });
                dispatch({
                    type: SET_POST_MSG,
                    payload: null
                });
            });
        };

        // return () => {
        //     dispatch({
        //         type: SET_POST,
        //         payload: {}
        //     });
        // };
		// eslint-disable-next-line
	}, []);

    useEffect(() => {
        if (!_.isEmpty(errorsState)) {
            setErrors(errorsState);
            setLoading(false);
        }
    }, [errorsState]);

    useEffect(() => {
        if (!isEmpty(errors.msg)) {
            setToastType('error');
            setToastTitle('ERROR');
            setToastMessage(errors.msg);
        }
    }, [errors]);

    useEffect(() => {
        if (msg) {
            setToastType('success');
            setToastTitle('SUCCESS');
            setToastMessage(msg);
            setLoading(false);
        }
    }, [msg]);

    useEffect(() => {
        if (toastMessage) {
            toast.current.handleClick();
            setTimeout(() => {
                setToastType('');
                setToastTitle('');
                setToastMessage('');
                dispatch({
                    type: SET_POST_MSG,
                    payload: null
                });
            }, 5000);
        }
    }, [dispatch, toastMessage]);

    const handleSetImage = (e) => {
        setImage(e.target.files[0]);
        const reader = new FileReader();

        reader.onload = (() => {
            const image = reader.result; //Array Buffer
            setImageSrc(image);
        });
        reader.readAsDataURL(e.target.files[0]);
    };

    const handleQuestionChange = (body) => {
        setBody(body);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setErrors({});

        const data = {
            title,
            body,
            image
        };

        const { errors, isValid } = validateEditPost(data);

        if (!isValid) {
            return setErrors({ ...errors, msg: 'Invalid post data!' });
        }

        if (!image) {
            return setErrors({ msg: 'Post image is required!' });
        }

        if ((image.size / 1000) > FILE_LIMIT) {
            return setErrors({ msg: 'File too large. Limit is 10MB!' });
        }

        let postData = new FormData();
        postData.append('image', image);
        postData.append('title', title);
        postData.append('body', body);
        postData.append('filename', post.filename);

        setErrors({});
        setLoading(true);

        props.editPost(postData, post._id);

    };

	return (
        <>
            <Toast 
                ref={toast}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            {loading && <Spinner />}
            <section className={classes.root}>
                <h1>Edit Post</h1>
                <form onSubmit={onSubmit} noValidate>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <TextField 
                                className={classes.input}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="Post Title"
                                placeholder="Enter Post Title"
                                helperText={errors.title}
                                fullWidth
                                required
                                error={errors.title ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextEditor 
                                className={classes.textEditor}
                                value={body} 
                                onChange={handleQuestionChange} 
                                placeholder="Start typing here to create an amazing article . . ."
                            />
                            {errors.body && <Typography variant="subtitle2" component="small" className={classes.postError}>{errors.body}</Typography>}
                        </Grid>
                        <Grid item xs={6}>
                            <input
                                accept="image/*"
                                className={classes.input}
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                onChange={handleSetImage}
                                type="file"
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="outlined" component="span" className={classes.uploadButton} color="primary">
                                    Select Post Image
                                </Button>
                            </label> 
                        </Grid>
                        <Grid item xs={12}>
                            {imageSrc && <img src={imageSrc} alt="" />}
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                className={classes.button}
                                variant="contained" 
                                color="primary"
                                type="submit"
                                // fullWidth
                            >
                                Update Post
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </section>
        </>
	);
}

EditPost.propTypes = {
    editPost: PropTypes.func.isRequired
};

export default connect(undefined, { editPost })(EditPost);