export const COLORS = {
    borderColor: 'rgb(224, 224, 224)',
    white: '#ffffff',
    lightGray: '#a8a8a8',
    offWhite: '#f8f8f8',
    offBlack: '#333333',
    securtyConsulting: '#FCDEE9'
};

export const FILE_LIMIT = 10240; // 10MB

export const SHADOW = 'rgb(0 0 0 / 12%) 0px 0px 20px 0px';

export const NETWORK_ERROR = 'Network Error';
export const SERVER_URL='http://localhost:4000';
export const CYBERKACH_AUTH_TOKEN = 'cyberkachAuthToken';

export const ADMIN = 'ADMIN';
export const AUTHOR = 'AUTHOR';
export const EDITOR = 'EDITOR';

export const APPROVED = 'APPROVED';
export const DRAFT = 'DRAFT';
export const PENDING = 'PENDING';