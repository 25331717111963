import { Link } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from '../utils/constants';

import img from '../assets/img/404.svg';
import logo from '../assets/img/logo.png';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: [[0, theme.spacing(5), theme.spacing(5), theme.spacing(5)]],
        height: '100%',

        '& img': {
            width: '40%',

            [theme.breakpoints.down('sm')]: {
                width: '80%'
            }
        },

        '& h2': {
            color: COLORS.offBlack,
            fontWeight: 600,
            marginTop: theme.spacing(3),

            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(4)
            }
        },

        '& p': {
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(3),

            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(1.2)
            }
        },

        '& small': {
            color: COLORS.lightGray,
            position: 'absolute',
            bottom: 10
        }
    },

    logo: {
        marginLeft: theme.spacing(5),
        marginTop: theme.spacing(2),
        width: '10%'
    }
}));

const NotFoundPage = () => {
    const classes = useStyles();

    return (
        <>
            <Link to="/">
                <img src={logo} alt="Cyberkach Logo" className={classes.logo} />
            </Link>
            <section className={classes.root}>
                <img src={img} alt="404" />
                <Typography variant="h2">PAGE NOT FOUND</Typography>
                <Typography variant="subtitle2" component="p">THE PAGE YOU ARE LOOKING FOR CAN'T BE FOUND!</Typography>
                <Button variant="contained" color="primary" component={Link} to="/">Take Me Home</Button>
                <Typography variant="subtitle2" component="small">&copy; Copyright {new Date().getFullYear()}, Cyberkach - All Rights Reserved</Typography>
            </section>
        </>
    );
};

export default NotFoundPage;