import { 
    SET_CURRENT_USER,
    UPDATED_PROFILE,
    SET_AUTH_MSG,
    SET_AVATAR
} from '../actions/types';

import isEmpty from '../utils/isEmpty';

const initialState = {
    authenticated: false,
    user: {},
    msg: null
};

const authReducer =  (state = initialState, action) => {
    // let users;
    // let currentUser;
    // let userId;
    // let userIndex;
    // let updatedUser;

    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                authenticated: !isEmpty(action.payload) ? true : false,
                user: { ...action.payload }
            };

        case UPDATED_PROFILE:
            return {
                ...state,
                user: action.payload.user,
                msg: action.payload.msg
            };

        case SET_AUTH_MSG:
            return {
                ...state,
                msg: action.payload
            };

        case SET_AVATAR:
            return {
                ...state,
                user: { ...state.user, avatar: action.payload.avatar },
                msg: action.payload.msg
            };

        default:
            return state;
    }
};

export default authReducer;