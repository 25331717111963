import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { 
    Box,
    Fab, 
    Link,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Plus } from 'mdi-material-ui';

import { GET_ERRORS, SET_USER_MSG } from '../../../actions/types';
import { getAllUsers } from '../../../actions/users';
import { CREATE_USER, DASHBOARD } from '../../../routes';
import isEmpty from '../../../utils/isEmpty';
import { ADMIN } from '../../../utils/constants';

import Admins from './Admins';
import Authors from './Authors';
import Editors from './Editors';

import Toast from '../../../components/common/Toast';

const useStyles = makeStyles(theme => ({
    tabs: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        width: '100%'
    },

    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 3,
        [theme.breakpoints.down('sm')]: {
            bottom: 60,
        }
    }
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Index = (props) => {
	const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const errorsState = useSelector(state => state.errors);
    const { admins, authors, visitors, msg } = useSelector(state => state.users);
    const { user } = useSelector(state => state.auth);

    const { getAllUsers, handleSetHeading, handleSetTitle } = props;

    const [value, setValue] = useState(0);
    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const [errors, setErrors] = useState({});

    const toast = useRef();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


	useEffect(() => {
        if (user.role !== ADMIN) {
            return navigate(-1);
        }
        if (isEmpty(admins) && isEmpty(authors) && isEmpty(visitors)) {
            getAllUsers();
        }
		handleSetTitle('Users');
        handleSetHeading('Create and Manage Users');
		// eslint-disable-next-line
	}, []);

    useEffect(() => {
        if (toastMessage) {
            toast.current.handleClick();
            dispatch({
                type: SET_USER_MSG,
                payload: null
            });
        }
    }, [dispatch, toastMessage]);

    useEffect(() => {
        if (!_.isEmpty(errorsState)) {
            setErrors(errorsState);
            dispatch({
                type: GET_ERRORS,
                payload: {}
            });
        }
    }, [dispatch, errorsState]);

    useEffect(() => {
        if (!isEmpty(errors.msg)) {
            setToastType('error');
            setToastTitle('ERROR');
            setToastMessage(errors.msg);
        }
    }, [errors]);

    useEffect(() => {
        if (msg) {
            setToastType('success');
            setToastTitle('SUCCESS');
            setToastMessage(msg);
        }
    }, [msg]);

	return (
        <>
            <Toast 
                ref={toast}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            {user.role === ADMIN && 
                <Fab 
                    color="primary" 
                    aria-label="Create User"
                    size="medium"
                    className={classes.fab}
                >
                    <Link to={`${DASHBOARD}${CREATE_USER}`} component={RouterLink} style={{ color: '#ffffff' }}>
                        <Plus />
                    </Link>
                </Fab>
            }
            <section>
                <Tabs 
                    value={value} 
                    onChange={handleChange} 
                    aria-label="users tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{ flexContainer: classes.tabs }}
                >
                    <Tab label="Admins" {...a11yProps(0)} />
                    <Tab label="Authors" {...a11yProps(1)} />
                    <Tab label="Editors" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Admins />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Authors />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Editors />
                </TabPanel>
            </section>
        </>
	);
}

Index.propTypes = {
    getAllUsers: PropTypes.func.isRequired
};

export default connect(undefined, { getAllUsers })(Index);