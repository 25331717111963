import axios from 'axios';

import handleError from '../utils/handleError';
import { 
    ADDED_PODCAST, 
    EDITED_PODCAST,
    SET_PODCASTS,
    DELETED_PODCAST
} from './types';

import { SERVER_URL } from '../utils/constants';

const api = process.env.NODE_ENV === 'production' ? '/podcasts' : `${SERVER_URL}/podcasts`;

export const createPodcast = (podcast) => async (dispatch) => {
    try {
        const res = await axios.post(api, podcast);
        const { data, msg } = res.data;

        return dispatch({
            type: ADDED_PODCAST,
            payload: { podcast: data, msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const editPodcast = (podcast, id) => async (dispatch) => {
    try {
        const res = await axios.put(`${api}/${id}`, podcast);
        const { data, msg } = res.data;
        return dispatch({
            type: EDITED_PODCAST,
            payload: { podcast: data, msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const getPodcasts = () => async (dispatch) => {
    try {
        const res = await axios.get(api);
        const { data } = res.data;
        return dispatch({
            type: SET_PODCASTS,
            payload: data
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const deletePodcast = (id) => async (dispatch) => {
    try {
        const res = await axios.delete(`${api}/${id}`);
        const { msg } = res.data;
        return dispatch ({
            type: DELETED_PODCAST,
            payload: { id, msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};