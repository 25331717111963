import Validator  from'validator';
import isEmpty from'../../isEmpty';

const editPost = (data) => {
    let errors = {};
    data.title = !isEmpty(data.title) ?  data.title : '';
    data.body = !isEmpty(data.body) ?  data.body : '';

    if (Validator.isEmpty(data.title)) {
        errors.title = 'Post title is required!';
    }

    if (Validator.isEmpty(data.body)) {
        errors.body = 'Post body is required!';
    }

    if (data.body.replace(/(<([^>]+)>)/gi, '') === '') {
        errors.body = 'Post body is required!';
    }
    
    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export default editPost;