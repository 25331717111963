import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip
} from '@material-ui/core';
import { FileMove, Delete } from 'mdi-material-ui';

import { deletePost, moveToDrafts } from '../../../actions/posts';

import { ADMIN, APPROVED } from '../../../utils/constants';

const columns = [
    { id: 'S/N', label: 'S/N', minWidth: 10 },
    { id: 'title', label: 'Title', minWidth: 100 },
    {
      id: 'author',
      label: 'Author',
      minWidth: 170,
    //   align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'date',
      label: 'Date',
      minWidth: 170,
    //   align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 170,
    //   align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    }
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',    
    },

    container: {
        maxHeight: 440,
    },

    tableHeader: {
        fontWeight: 600
    },

    pagination: {
        display: 'flex'
    }
}));

const ApprovedPosts = ({ deletePost, moveToDrafts }) => {
    const classes = useStyles();

    const posts = useSelector(state => state.posts.approved);
    const { user } = useSelector(state => state.auth);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleMoveToDrafts = (postId) => {
        moveToDrafts(postId, APPROVED);
    };

    const handleDeletePost = (postId) => {
        const confirmDelete = window.confirm('Delete Post?');

        if (confirmDelete) {
            deletePost(postId, APPROVED);
        }
    };

    return (
        <Paper>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    className={classes.tableHeader}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {posts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((post, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={post._id}>
                                    <TableCell>{index + 1}.</TableCell>
                                    <TableCell>{post.title}</TableCell>
                                    <TableCell>{`${post.author.firstName} ${post.author.lastName}`}</TableCell>
                                    <TableCell>{moment(post.createdAt).format('MMM Do, YYYY')}</TableCell>
                                    <TableCell>
                                        <>
                                            <IconButton 
                                                disabled={!post.author === user._id || !user.role === ADMIN ? true : false}
                                                onClick={() => handleMoveToDrafts(post._id)}
                                            >
                                                <Tooltip arrow title="Move to drafts">
                                                    <FileMove />
                                                </Tooltip>
                                            </IconButton>
                                            <IconButton
                                                disabled={!post.author === user._id || !user.role === ADMIN ? true : false}
                                            >
                                                <Tooltip arrow title="Delete Post" onClick={() => handleDeletePost(post._id)}>
                                                    <Delete />
                                                </Tooltip>
                                            </IconButton>
                                        </>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={posts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                classes={{
                    root: classes.pagination
                }}
            />
        </Paper>
    );
};

ApprovedPosts.propTypes = {
    deletePost: PropTypes.func.isRequired,
    moveToDrafts: PropTypes.func.isRequired,
};

export default connect(undefined, { deletePost, moveToDrafts })(ApprovedPosts);