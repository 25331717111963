import { combineReducers } from 'redux';

import { RESET_STORE } from '../actions/types';

import authReducer from './auth';
import postsReducer from './posts';
import podcastsReducer from './podcasts';
import usersReducer from './users';
import errorsReducer from './errors';

const appReducer = combineReducers({
    auth: authReducer,
    posts: postsReducer,
    podcasts: podcastsReducer,
    users: usersReducer,
    errors: errorsReducer
});

const rootReducer = (state, action) => {
    if (action.type === RESET_STORE) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;