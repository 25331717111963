import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
// import { Beforeunload } from 'react-beforeunload';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Toaster } from 'react-hot-toast';


import ScrollToTop from './components/common/ScrollToTop';
import PrivateRoute from './components/common/PrivateRoute';

import Login from './pages/auth/Login';
import VerifyOtp from './pages/auth/VerifyOtp';

import Dashboard from './pages/dashboard';
import DashboardHome from './pages/dashboard/home';
import Posts from './pages/dashboard/posts';
import CreatePost from './pages/dashboard/posts/CreatePost';
import CreatePodcast from './pages/dashboard/posts/CreatePodcast';
import EditPodcast from './pages/dashboard/posts/EditPodcast';
import EditPost from './pages/dashboard/posts/EditPost';
import Users from './pages/dashboard/users';
import CreateUser from './pages/dashboard/users/CreateUser';
import EditUser from './pages/dashboard/users/EditUser';
import UserProfile from './pages/dashboard/profile';

import NotFoundPage from './pages/NotFoundPage';

import setAuthToken from './utils/setAuthToken';

import { CYBERKACH_AUTH_TOKEN } from './utils/constants';
import { getMe } from './actions/auth';

import { CREATE_POST, DASHBOARD, LOGIN, CREATE_PODCAST, EDIT_PODCAST, EDIT_POST, CREATE_USER, EDIT_USER, DASHBOARD_HOME, POSTS, USERS, PROFILE, VERIFY_OTP, FORGOT_PASSWORD, RESET_PASSWORD } from './routes';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';

const theme = createTheme({
	overrides: {
		MuiButton: {
		  	root: {
				// borderRadius: '20px'
		  	},
		},
	},
	palette: {
		primary: {
			light: '#df3c3a',
			main: '#D11715',
			dark: '#990300'
		},

		secondary: {
			main: '#333333'
		},

		text: {
			// primary: '#333333',
			// secondary: '#333333'
		}
	},

	breakpoints: {
		values: {
			xs: 0,
			sm: 480,
			md: 768,
			lg: 1024,
			xl: 1920
		}
	},

	typography: {
		fontFamily: "'Quicksand', sans-serif",
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 600,

		button: {
			borderRadius: '25px'
		}
	}
});

const App = ({ getMe}) => {
	const navigate = useNavigate();

	const [title, setTitle] = useState('');
	const [heading, setHeading] = useState('');


	useEffect(() => {
		const token = sessionStorage.getItem(CYBERKACH_AUTH_TOKEN);
		if (token) {
			setAuthToken(token, CYBERKACH_AUTH_TOKEN);
			getMe(navigate);
		}

		// eslint-disable-next-line
	}, []);

	const handleSetHeading = (heading) => setHeading(heading);
	
	const handleSetTitle = (title) => setTitle(title);

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<ThemeProvider theme={theme}>
			<Toaster />
				<ScrollToTop>
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path={LOGIN} exact element={<Login />} />
						<Route path={FORGOT_PASSWORD} exact element={<ForgotPassword />} />
						<Route path={RESET_PASSWORD} exact element={<ResetPassword />} />
						<Route path={VERIFY_OTP} exact element={<VerifyOtp />} />
						<Route path={DASHBOARD} element={<PrivateRoute><Dashboard heading={heading} title={title} /></PrivateRoute>}>
							<Route path={`${DASHBOARD}${DASHBOARD_HOME}`} element={<DashboardHome handleSetTitle={handleSetTitle} handleSetHeading={handleSetHeading} />} />
							<Route path={`${DASHBOARD}${POSTS}`} element={<Posts handleSetTitle={handleSetTitle} handleSetHeading={handleSetHeading} />} />
							<Route path={`${DASHBOARD}${CREATE_POST}`} element={<CreatePost handleSetTitle={handleSetTitle} handleSetHeading={handleSetHeading} />} />
							<Route path={`${DASHBOARD}${CREATE_PODCAST}`} element={<CreatePodcast handleSetTitle={handleSetTitle} handleSetHeading={handleSetHeading} />} />
							<Route path={`${DASHBOARD}${EDIT_PODCAST}`} element={<EditPodcast handleSetTitle={handleSetTitle} handleSetHeading={handleSetHeading} />} />
							<Route path={`${DASHBOARD}${EDIT_POST}`} element={<EditPost handleSetTitle={handleSetTitle} handleSetHeading={handleSetHeading} />} />
							<Route path={`${DASHBOARD}${USERS}`} element={<Users handleSetTitle={handleSetTitle} handleSetHeading={handleSetHeading} />} />
							<Route path={`${DASHBOARD}${CREATE_USER}`} element={<CreateUser handleSetTitle={handleSetTitle} handleSetHeading={handleSetHeading} />} />
							<Route path={`${DASHBOARD}${EDIT_USER}`} element={<EditUser handleSetTitle={handleSetTitle} handleSetHeading={handleSetHeading} />} />
							<Route path={`${DASHBOARD}${PROFILE}`} element={<UserProfile handleSetTitle={handleSetTitle} handleSetHeading={handleSetHeading} />} />
						</Route>
						<Route element={<NotFoundPage />} />
					</Routes>
				</ScrollToTop>
			</ThemeProvider>
		</MuiPickersUtilsProvider>
	);
}

App.propTypes = {
	getMe: PropTypes.func.isRequired
};

export default connect(undefined, { getMe })(App);