import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link as RouterLink, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { logout } from '../../actions/auth';

import logo from '../../assets/img/logo.png';
import smallLogo from '../../assets/img/icon.png';

import {
    AppBar,
    Avatar,
    Box,
    IconButton,
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    BottomNavigation,
    BottomNavigationAction,
    Toolbar,
    Tooltip,
    Typography
} from '@material-ui/core';

import { Account, AccountMultiple, ChevronRight, ChevronLeft, HomeMinus, FormatListText, Logout } from 'mdi-material-ui';
import { DASHBOARD_HOME, POSTS, USERS, PROFILE } from '../../routes';

import { ADMIN, COLORS } from '../../utils/constants';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginTop: theme.spacing(3),

        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(8)
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5)
        }
    },

    content: {
        flexGrow: 1,
        marginLeft: theme.spacing(9) + 1,
        marginTop: theme.spacing(5),
        zIndex: '997',
        width: `calc(100% - ${theme.spacing(7) + 1}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

        [theme.breakpoints.down('sm')]: {
            marginLeft: '0 !important',
            height: '100vh !important',
            width: '100% !important'
        }
    },

    contentShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
    },

    appBar: {
        backgroundColor: '#ffffff',
        borderBottom: `1px solid ${COLORS.borderColor}`,
        zIndex: 999,
        marginLeft: theme.spacing(9) + 1,
        width: `calc(100% - ${theme.spacing(7) + 1}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),

        [theme.breakpoints.down('sm')]: {
            marginLeft: '0 !important',
            width: '100% !important'
        }
    },

    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },

    appBarContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    title: {
        
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },

    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },

    logo: {
        width: '100%'
    },

    smallLogoContainer: {
        display: 'flex',
        flexDirection: 'column'
    },

    smallLogo: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: '100%'
    },

    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // padding: theme.spacing(2, 1),
        padding: [[theme.spacing(2), theme.spacing(2), 0, theme.spacing(2)]],
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },

    link: {
        backgroundColor: `#d117151a !important`,
        color: theme.palette.primary.main
    },

    links: {
        marginTop: theme.spacing(4.5)
    },

    linkItem: {
        marginBottom: theme.spacing(2)
    },

    icon: {
        color: theme.palette.primary.main
    },

    bottomBar: {
        display: 'none',
        position: 'fixed',
        bottom: 0,
        width: '100vw',
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    }
}));

const Dashboard = ({ title, heading, logout }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const { user } = useSelector(state => state.auth); 

    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(true);
    const [path, setPath] = useState('');

    const links = [
        { url : DASHBOARD_HOME, text:'Dashboard', icon: <HomeMinus /> },
        { url : POSTS, text:'Posts', icon: <FormatListText /> },
        { url : USERS, text:'Users', icon: <AccountMultiple /> },
        { url : PROFILE, text:'Profile', icon: <Account /> }
    ];

    const mobileLinks = [
        { url : DASHBOARD_HOME, text:'Dashboard', icon: <HomeMinus /> },
        { url : POSTS, text:'Posts', icon: <FormatListText /> },
        { url : USERS, text:'Users', icon: <AccountMultiple /> },
        { url : PROFILE, text:'Profile', icon: <Account /> }
    ];

    useEffect(() => {
        setPath(location.pathname);
    }, [location]);

    // eslint-disable-next-line
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };
    
    // eslint-disable-next-line
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLinkClick = (link) => {
        navigate(`/dashboard${link}`);
    };

    const handleLogout = () => logout(navigate);

    return (
        <>
            <Helmet><title>{`${title} | Cyberkach.com`}</title></Helmet>
            <section className={classes.root}>
                <AppBar position="fixed" color="transparent" elevation={0} className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                    <Toolbar className={classes.appBarContent}>
                        <Typography variant="h6" className={classes.title}>
                            {heading}
                        </Typography>
                        {user.avatar ?
                            <Avatar src={user.avatar} alt={user.username} />
                            :
                            <Avatar>
                                <Account />
                            </Avatar>
                        }
                    </Toolbar>
                </AppBar>
                <div className={clsx(classes.content, {
                    [classes.contentShift]: open})}
                >
                    <Outlet />
                </div>
                <Drawer 
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })}
                    classes={{
                        paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        {open ?
                            <> 
                                <RouterLink to="/">
                                    <img className={classes.logo} src={logo} alt="Cyberkach Logo" />
                                </RouterLink>
                                <IconButton onClick={toggleDrawer}>
                                    <Tooltip title="Collapse Navigation" placement="top" arrow>
                                        <ChevronLeft />
                                    </Tooltip>
                                </IconButton>
                            </>
                            :
                            <div className={classes.smallLogoContainer}>
                                <IconButton onClick={toggleDrawer}>
                                    <Tooltip title="Expand Navigation" placement="top" arrow>
                                        <ChevronRight />
                                    </Tooltip>
                                </IconButton>
                                <img className={classes.smallLogo} src={smallLogo} alt="Cyberkach Logo" />
                            </div>
                        }
                    </div> 
                    <List className={classes.links}>
                        {links.map((link, index) => {
                            if (user.role !== ADMIN && link.url === USERS) {
                                return null;
                            }
                            return (
                                <Tooltip key={index} title={open ? '' : link.text} placement="bottom" arrow>
                                    <ListItem 
                                        className={clsx({ [classes.link]: path.includes(`${link.url}`) }, classes.linkItem)} 
                                        button 
                                        disableRipple
                                        onClick={() => handleLinkClick(link.url)}
                                    >
                                        <ListItemIcon className={clsx({ [classes.icon]: path.includes(`${link.url}`) })} >
                                            {link.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={link.text} />
                                    </ListItem>
                                </Tooltip>
                            )
                        })}
                    </List>
                    <Divider />
                    <section className={classes.logoutContainer}>
                        <ListItem button className={classes.logout} onClick={handleLogout}>
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </section>
                </Drawer>
                <Box
                    boxShadow={5}
                    className={classes.bottomBar}
                >
                    <BottomNavigation
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue)
                        }}
                        showLabels
                    >
                        {mobileLinks.map((item, index) => (
                            <BottomNavigationAction onClick={() => handleLinkClick(item.url)} key={index} label={item.text} icon={item.icon} />
                        ))}
                    </BottomNavigation>
                </Box>
            </section>
        </>
    );
};

Dashboard.propTypes = {
    title: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired
};

export default connect(undefined, { logout })(Dashboard);