import axios from 'axios';
import { RESET_STORE, SET_CURRENT_USER, SET_AUTH_MSG, SET_AVATAR, TOGGLE_ADMIN, UPDATED_PROFILE } from './types';

import setAuthToken from '../utils/setAuthToken';
import handleError from '../utils/handleError';
import { DASHBOARD, DASHBOARD_HOME, LOGIN, VERIFY_OTP } from '../routes';
import { CYBERKACH_AUTH_TOKEN } from '../utils/constants';

const API = '/auth';

export const login = (data, navigate) => async (dispatch) => {
    try {
        const res = await axios.post(`${API}/login`, data);
        const { email } = res.data.data;
        // setAuthToken(token, 'auth');
        return navigate(VERIFY_OTP, { state: { email } });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const verifyOtp = (otp, navigate) => async (dispatch) => {
    try {
        const res = await axios.post(`${API}/verifyOtp`, { otp });
        const { token, user } = res.data.data;

        setAuthToken(token, CYBERKACH_AUTH_TOKEN);
        dispatch({
            type: SET_CURRENT_USER,
            payload: user
        });

        return navigate(`${DASHBOARD}${DASHBOARD_HOME}`);
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const resendOtp = (email) => async (dispatch) => {
    try {
        const res = await axios.post(`${API}/resendOtp`, { email });
        dispatch({
            type: SET_AUTH_MSG,
            payload: res.data.msg || 'OTP Resent Successfully'
        })
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const forgotPassword = (email) => async (dispatch) => {
    try {
        const res = await axios.post(`${API}/forgotPassword`, { email });
        dispatch({
            type: SET_AUTH_MSG,
            payload: res.data.msg || `We have sent a link to ${email}`
        })
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const resetPassword = (data, token, navigate) => async (dispatch) => {
    try {
        const res = await axios.post(`${API}/resetPassword/${token}`, data);
        dispatch({
            type: SET_AUTH_MSG,
            payload: res.data.msg || `Password reset successfully`
        })
        setTimeout(() => {
            navigate(LOGIN);
        }, 5000);
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const getMe = (navigate) => async (dispatch) => {
    try {
        const res = await axios.get(API);
        dispatch({
            type: SET_CURRENT_USER,
            payload: res.data.data
        });

        return navigate(`${DASHBOARD}${DASHBOARD_HOME}`);
    } catch (err) {
        setAuthToken(null, 'auth');
        setAuthToken(null, CYBERKACH_AUTH_TOKEN);
        navigate('/');
        // return handleError(err, dispatch);
    }
};

export const updateProfile = (data) => async (dispatch) => {
    try {
        const res = await axios.put(API, data);
        dispatch({
            type: UPDATED_PROFILE,
            payload: { user: res.data.data, msg: res.data.msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const changePassword = (data) => async (dispatch) => {
    try {
        await axios.put(`${API}/changePassword`, data);
        dispatch({
            type: SET_AUTH_MSG,
            payload: 'Password changed successfully'
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const uploadAvatar = (image) => async (dispatch) => {
    try {
        const res = await axios.put(`${API}/avatar`, image);
        const { data, msg } = res.data;
        dispatch({
            type: SET_AVATAR,
            payload: { msg, avatar: data.avatar }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const deleteAvatar = () => async (dispatch) => {
    try {
        const res = await axios.delete(`${API}/avatar`);
        const { data, msg } = res.data;
        dispatch({
            type: SET_AVATAR,
            payload: { msg, avatar: data.avatar }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const toggleAdmin = (adminId, userId) => async (dispatch) => {
    try {
        const res = await axios.get(`${API}/toggleAdmin/${adminId}/${userId}`);
        if (adminId === userId) {
            dispatch({
                type: SET_CURRENT_USER,
                payload: res.data.data
            });
        }
        return dispatch({
            type: TOGGLE_ADMIN,
            payload: { user: res.data.data, msg: res.data.msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const logout = (navigate) => (dispatch) => {
    dispatch({ type: RESET_STORE });
    setAuthToken(null, CYBERKACH_AUTH_TOKEN);
    setAuthToken(null, 'auth');
    return navigate('/');
};