import { useEffect, useRef, useState } from 'react';
import { useNavigate} from 'react-router-dom';
import { connect, batch, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip
} from '@material-ui/core';
import { CheckCircle, FileDocumentEdit, Delete } from 'mdi-material-ui';

import { GET_ERRORS, SET_POST, SET_POST_MSG } from '../../../actions/types';
import { deletePost, publishPost } from '../../../actions/posts';
import { ADMIN, AUTHOR, DRAFT } from '../../../utils/constants';
import { DASHBOARD, EDIT_POST } from '../../../routes';

import Toast from '../../../components/common/Toast';

const columns = [
    { id: 'S/N', label: 'S/N', minWidth: 10 },
    { id: 'title', label: 'Title', minWidth: 100 },
    {
      id: 'author',
      label: 'Author',
      minWidth: 170,
    //   align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'date',
      label: 'Date',
      minWidth: 170,
    //   align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 170,
    //   align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    }
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',    
    },

    container: {
        maxHeight: 440,
    },

    tableHeader: {
        fontWeight: 600
    },

    pagination: {
        display: 'flex'
    }
}));

const PublishedPosts = ({ deletePost, publishPost }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { msg } = useSelector(state => state.posts);
    const posts = useSelector(state => state.posts.drafts);
    const { user } = useSelector(state => state.auth);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const toast = useRef();

    useEffect(() => {
        return () => {
            batch(() => {
                dispatch({
                    type: GET_ERRORS,
                    payload: {}
                });
                dispatch({
                    type: SET_POST_MSG,
                    payload: null
                });
            });
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (msg) {
            setToastType('success');
            setToastTitle('SUCCESS');
            setToastMessage(msg);
            // setLoading(false);
        }
    }, [msg]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleEditPost = (post) => {
        dispatch({
            type: SET_POST,
            payload: post
        });
        navigate(`${DASHBOARD}${EDIT_POST}`);
    };

    const handlePublishPost = (postId) => {
        publishPost(postId);
    };

    const handleDeletePost = (postId) => {
        const confirmDelete = window.confirm('Delete Post?');

        if (confirmDelete) {
            deletePost(postId, DRAFT);
        }
    };

    return (
        <>
            <Toast 
                ref={toast}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            <Paper>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        className={classes.tableHeader}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {posts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((post, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={post._id}>
                                        <TableCell>{index + 1}.</TableCell>
                                        <TableCell>{post.title}</TableCell>
                                        <TableCell>{`${post.author.firstName} ${post.author.lastName}`}</TableCell>
                                        <TableCell>{moment(post.createdAt).format('MMM Do, YYYY')}</TableCell>
                                        <TableCell>
                                            {user.role !== AUTHOR && 
                                                <>
                                                <IconButton>
                                                        <Tooltip arrow title="Publish Post" onClick={() => handlePublishPost(post._id)}>
                                                            <CheckCircle />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton onClick={() => handleEditPost(post)}>
                                                        <Tooltip arrow title="Edit Post">
                                                            <FileDocumentEdit />
                                                        </Tooltip>
                                                    </IconButton>
                                                </>
                                            }
                                            {user.role === ADMIN && 
                                                <IconButton>
                                                    <Tooltip arrow title="Delete Post"
                                                        disabled={!post.author === user._id || !user.role === ADMIN ? true : false}
                                                        onClick={() => handleDeletePost(post._id)}
                                                    >
                                                        <Delete />
                                                    </Tooltip>
                                                </IconButton>
                                            }               
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={posts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        root: classes.pagination
                    }}
                />
            </Paper>
        </>
    );
};

PublishedPosts.propTypes = {
    publishPost: PropTypes.func.isRequired,
    deletePost: PropTypes.func.isRequired
};

export default connect(undefined, { publishPost, deletePost })(PublishedPosts);