import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { 
    Badge,
    Box,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { makeStyles } from '@material-ui/core/styles';
import { BookmarkCheckOutline, BookEditOutline, BookOutline, Podcast, Post } from 'mdi-material-ui';

import { getAllPosts } from '../../../actions/posts';
import { CREATE_POST, CREATE_PODCAST, DASHBOARD } from '../../../routes';
import isEmpty from '../../../utils/isEmpty';

import ApprovedPosts from './ApprovedPosts';
import Drafts from './Drafts';
import PendingPosts from './PendingPosts';
import Podcasts from './Podcasts';

import Toast from '../../../components/common/Toast';
import { GET_ERRORS } from '../../../actions/types';
import { ADMIN } from '../../../utils/constants';

const useStyles = makeStyles(theme => ({
    tabs: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        width: '100%'
    },

    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 3,
        [theme.breakpoints.down('sm')]: {
            bottom: 60,
        }
    }
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Index = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
    const dispatch = useDispatch();

    const errorsState = useSelector(state => state.errors);
    const { drafts, pending, approved, msg } = useSelector(state => state.posts);
    const user = useSelector(state => state.auth.user);

    const { getAllPosts, handleSetHeading, handleSetTitle } = props;

    const [value, setValue] = useState(0);
    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const [open, setOpen] = useState(false);
    // eslint-disable-next-line
    const [hidden, setHidden] = useState(false);

    const [errors, setErrors] = useState({});

    const toast = useRef();

    const actions = [
        { icon: <Post />, name: 'New Blog Post', url: `${DASHBOARD}${CREATE_POST}` },
        { icon: <Podcast />, name: 'New Podcast', url: `${DASHBOARD}${CREATE_PODCAST}` }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


	useEffect(() => {
        if (isEmpty(approved) && isEmpty(drafts) && isEmpty(pending)) {
            getAllPosts();
        }
		handleSetTitle('Posts');
        handleSetHeading('Create and Manage Posts');
        dispatch({
            type: GET_ERRORS,
            payload: {}
        });
		// eslint-disable-next-line
	}, []);

    useEffect(() => {
        if (toastMessage) {
            toast.current.handleClick();
        }
    }, [toastMessage]);

    useEffect(() => {
        if (!_.isEmpty(errorsState)) {
            setErrors(errorsState);
        }
    }, [errorsState]);

    useEffect(() => {
        if (!isEmpty(errors.msg)) {
            setToastType('error');
            setToastTitle('ERROR');
            setToastMessage(errors.msg);
        }
    }, [errors]);

    useEffect(() => {
        if (msg) {
            setToastType('success');
            setToastTitle('SUCCESS');
            setToastMessage(msg);
        }
    }, [msg]);
    
    const handleClose = () => {
        setOpen(false);
    };
    
    const handleOpen = () => {
        setOpen(true);
    }

	return (
        <>
            <Toast 
                ref={toast}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            <SpeedDial
                ariaLabel="SpeedDial example"
                className={classes.fab}
                hidden={hidden}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction="up"
            >
                {actions.map((action) => {
                    if (action.url === `${DASHBOARD}${CREATE_PODCAST}` && user.role !== ADMIN) {
                        return null;
                    }
                    return (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={() => navigate(action.url)}
                        />
                    )})}
            </SpeedDial>
            <section className={classes.root}>
                <Tabs 
                    value={value} 
                    onChange={handleChange} 
                    aria-label="posts tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{ flexContainer: classes.tabs }}
                >
                    <Tab icon={<Badge color="primary" overlap="rectangular" badgeContent={0}><BookmarkCheckOutline /></Badge>} label="Approved" {...a11yProps(0)} />
                    <Tab icon={<BookEditOutline />}  label="Drafts" {...a11yProps(1)} />
                    <Tab icon={<BookOutline />} label="Pending" {...a11yProps(2)} />
                    {user.role === ADMIN && 
                        <Tab icon={<Podcast />} label="Podcasts" {...a11yProps(2)} />
                    }
                </Tabs>
                <TabPanel value={value} index={0}>
                    <ApprovedPosts />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Drafts />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <PendingPosts />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Podcasts />
                </TabPanel>
            </section>
        </>
	);
}

Index.propTypes = {
    getAllPosts: PropTypes.func.isRequired
};

export default connect(undefined, { getAllPosts })(Index);