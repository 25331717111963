import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { 
    Button, 
    Grid, 
    IconButton,
    InputAdornment,
    TextField, 
    Tooltip,
    Typography 
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { EyeOutline, EyeOffOutline } from 'mdi-material-ui';
import Spinner from '../../components/common/Spinner';
import Toast from '../../components/common/Toast';

import { resetPassword } from '../../actions/auth';
import isEmpty from '../../utils/isEmpty';
import { COLORS } from '../../utils/constants';
import validateResetPassword from '../../utils/validation/auth/resetPassword';
import { GET_ERRORS, SET_AUTH_MSG } from '../../actions/types';

import background from '../../assets/img/about.jpg';
import logo from '../../assets/img/icon-small.png';
import { DASHBOARD, DASHBOARD_HOME } from '../../routes';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: COLORS.offWhite,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        overflowY: 'hidden'
    },
    
    gridContainer: {
        borderRadius: theme.shape.borderRadius,
        height: '80vh',
        overflow: 'hidden',
        width: '75%'
    },

    image: {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',

        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },

    formContainer: {
        backgroundColor: COLORS.white,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        padding: [[theme.spacing(8), theme.spacing(4)]],

        '& h6': {
            textAlign: 'center'
        }
    },

    subTitle: {
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(2),
        textAlign: 'center'
    },

    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(5),

        [theme.breakpoints.down('md')]: {
            display: 'none'
        },

        '& img': {
            display: 'block',
            width: '25%'
        },
    }
}));


const ResetPassword = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();

    const dispatch = useDispatch();
    const errorsState = useSelector(state => state.errors);
    const { authenticated, msg } = useSelector(state => state.auth);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const { resetPassword } = props;

    const toast = useRef();
    const resetToken = params.token;

    useEffect(() => {
        if (authenticated) {
            navigate(`${DASHBOARD}${DASHBOARD_HOME}`, { replace: true });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!_.isEmpty(errorsState)) {
            setErrors(errorsState);
            setLoading(false);
            dispatch({
                type: GET_ERRORS,
                payload: {}
            });
        }
    }, [dispatch, errorsState]);

    useEffect(() => {
        if (!isEmpty(errors.msg)) {
            setToastType('error');
            setToastTitle('ERROR');
            setToastMessage(errors.msg);
        }
    }, [errors]);

    useEffect(() => {
        if (msg) {
            setToastMessage(msg);
            setToastType('success');
            setToastTitle('SUCCESS');
            setLoading(false);
            setPassword('');
            setConfirmPassword('');
            dispatch({
                type: SET_AUTH_MSG,
                payload: null
            });
        }
    }, [dispatch, msg]);

    useEffect(() => {
        if (toastMessage) {
            toast.current.handleClick();
        }
    }, [toastMessage]);

    const onSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        dispatch({
            type: GET_ERRORS,
            payload: {}
        });

        const data = { password, confirmPassword }
        const { errors, isValid } = validateResetPassword(data);

        if (!isValid) {
            return setErrors(errors);
        }

        setLoading(true);
        setErrors({});
        resetPassword(data, resetToken, navigate);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    return (
        <>
            <Toast 
                ref={toast}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            {loading && <Spinner />}
            <section className={classes.root}>
                <Grid container direction="row" className={classes.gridContainer}>
                    <Grid item xs={12} lg={6} className={classes.image}>
                        
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.formContainer}>
                        <form onSubmit={onSubmit} noValidate>
                            <div className={classes.imageContainer}>
                                <img src={logo} alt="Cyberkach Logo" />
                            </div>
                            <Typography variant="h6">Reset your Password</Typography>
                            <Typography variant="body1" componet="p" className={classes.subTitle}>Enter a new password to recover your account.</Typography>
                            <Grid container direction="column" spacing={5}>
                                <Grid item xs={12}>
                                    <TextField 
                                        className={classes.input}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={showPassword ? 'text': 'password'}
                                        variant="outlined" 
                                        label="Password"
                                        placeholder="Enter Password"
                                        helperText={errors.password}
                                        fullWidth
                                        required
                                        error={errors.password  ? true : false}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={toggleShowPassword}
                                                    >
                                                        {showPassword ? 
                                                            <Tooltip title="Hide password" placement="bottom" arrow>
                                                                <EyeOffOutline />
                                                            </Tooltip>
                                                                : 
                                                            <Tooltip title="Show password" placement="bottom" arrow>
                                                                <EyeOutline />
                                                            </Tooltip>
                                                            }
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        className={classes.input}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        type={showConfirmPassword ? 'text': 'password'}
                                        variant="outlined" 
                                        label="Confirm Password"
                                        placeholder="Re-enter your Password"
                                        helperText={errors.confirmPassword}
                                        fullWidth
                                        required
                                        error={errors.confirmPassword  ? true : false}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={toggleShowConfirmPassword}
                                                    >
                                                        {showConfirmPassword ? 
                                                            <Tooltip title="Hide password" placement="bottom" arrow>
                                                                <EyeOffOutline />
                                                            </Tooltip>
                                                                : 
                                                            <Tooltip title="Show password" placement="bottom" arrow>
                                                                <EyeOutline />
                                                            </Tooltip>
                                                            }
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button 
                                        className={classes.button}
                                        variant="contained" 
                                        color="primary"
                                        type="submit"
                                        fullWidth
                                        disabled={loading}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </section>
        </>
    );
};

ResetPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired
};

export default connect(undefined, { resetPassword })(ResetPassword);