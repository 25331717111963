import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
	}
}));

const Index = (props) => {
	const classes = useStyles();

    const { handleSetTitle } = props;


	useEffect(() => {
		handleSetTitle('Dashboard');
		// eslint-disable-next-line
	}, []);

	return (
		<section className={classes.root} id="parent">
			<h1>Dashboard</h1>
		</section>
	);
}

export default Index;