import { 
    ADDED_PODCAST, 
    EDITED_PODCAST,
    SET_PODCAST,
    SET_PODCASTS, 
    DELETED_PODCAST,
    SET_PODCAST_MSG
} from '../actions/types';

const initialState = {
    msg: null,
    podcasts: [],
    podcast: {}
};

const podcastsReducer = (state = initialState, action) => {
    let podcasts = [];

    switch (action.type) {
        case SET_PODCAST:
            return {
                ...state,
                podcast: action.payload
            };

        case SET_PODCASTS:
            return {
                ...state,
                podcasts: action.payload
            };

        case ADDED_PODCAST: 
            return {
                ...state,
                podcasts: [action.payload.podcast, ...state.podcasts],
                msg: action.payload.msg
            }

        case EDITED_PODCAST: 
            podcasts = state.podcasts;
            podcasts.splice(podcasts.findIndex(item => item._id === action.payload.podcast._id), 1, action.payload.podcast);

            return {
                ...state,
                podcast: action.payload.podcast,
                podcasts: [...podcasts],
                msg: action.payload.msg
            }

        case SET_PODCAST_MSG:
            return {
                ...state,
                msg: action.payload
            };

        case DELETED_PODCAST:
            podcasts = state.podcasts;
            podcasts.splice(podcasts.findIndex(item => item._id === action.payload.id), 1);

            return {
                ...state,
                podcasts: [...state.podcasts],
                msg: action.payload.msg
            };

        default:
            return state;
    }
};

export default podcastsReducer;