export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';
export const SET_POST = 'SET_POST';
export const SET_POSTS = 'SET_POSTS';
export const SET_MORE_POSTS = 'SET_MORE_POSTS';
export const ADDED_POST = 'ADDED_POST';
export const EDITED_POST = 'EDITED_POST';
export const SET_PENDING_POSTS = 'SET_PENDING_POSTS';
export const SET_DRAFT_POSTS = 'SET_DRAFT_POSTS';
export const SET_APPROVED_POSTS = 'SET_APPROVED_POSTS';
export const PUBLISHED_POST = 'PUBLISHED_POST';
export const MOVE_TO_DRAFTS = 'MOVE_TO_DRAFTS';
export const APPROVED_POST = 'APPROVED_POST';
export const SET_POST_MSG = 'SET_POST_MSG';
export const DELETED_POST = 'DELETED_POST';
export const ADDED_USER = 'ADDED_USER';
export const EDITED_USER = 'EDITED_USER';
export const DELETED_USER = 'DELETED_USER';
export const SET_ADMINS = 'SET_ADMINS';
export const SET_AUTHORS = 'SET_AUTHORS';
export const SET_EDITORS = 'SET_EDITORS';
export const SET_USER = 'SET_USER';
export const SET_USER_MSG = 'SET_USER_MSG';
export const TOGGLE_ADMIN = 'TOGGLE_ADMIN';
export const TOGGLE_DISABLED_USER = 'TOGGLE_DISABLED_USER';
export const UPDATED_PROFILE = 'UPDATED_PROFILE';
export const SET_AUTH_MSG = 'SET_AUTH_MSG';
export const SET_AVATAR = 'SET_AVATAR';
export const ADDED_PODCAST = 'ADDED_PODCAST';
export const EDITED_PODCAST = 'EDITED_PODCAST';
export const SET_PODCAST = 'SET_PODCAST';
export const SET_PODCASTS = 'SET_PODCASTS';
export const DELETED_PODCAST = 'DELETED_PODCAST';
export const SET_PODCAST_MSG = 'SET_PODCAST_MSG';
export const RESET_STORE = 'RESET_STORE';