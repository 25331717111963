import Validator from 'validator';
import isEmpty from '../../isEmpty';

import { ADMIN, AUTHOR } from '../../constants';

const create = (data) => {
    let errors = {};
    data.firstName = !isEmpty(data.firstName) ?  data.firstName : '';
    data.lastName = !isEmpty(data.lastName) ?  data.lastName : '';
    data.email = !isEmpty(data.email) ?  data.email : '';
    data.role = !isEmpty(data.role) ?  data.role : '';

    if (Validator.isMobilePhone(data.firstName)) {
        errors.firstName = 'Last name cannot be a phone number!';
    }
    if (Validator.isNumeric(data.firstName)) {
        errors.firstName = 'First name cannot be a number!';
    }
    if (Validator.isEmail(data.firstName)) {
        errors.firstName = 'First name cannot be an email!';
    }
    if (Validator.isEmpty(data.firstName)) {
        errors.firstName = 'First name is required!';
    }

    if (Validator.isMobilePhone(data.lastName)) {
        errors.lastName = 'Last name cannot be a phone number!';
    }
    if (Validator.isNumeric(data.lastName)) {
        errors.lastName = 'Last name cannot be a number!';
    }
    if (Validator.isEmail(data.lastName)) {
        errors.lastName = 'Last name cannot be an email!';
    }
    if (Validator.isEmpty(data.lastName)) {
        errors.lastName = 'Last name is required!';
    }
    
    if (Validator.isEmpty(data.email)) {
        errors.email = 'Email Address is required!';
    }
    if (!Validator.isEmail(data.email)) {
        errors.email = 'Invalid Email Address!';
    }

    if (Validator.isEmpty(data.role)) {
        errors.role = 'User role is required!';
    }
    if (!data.role === ADMIN || !data.role === AUTHOR) {
        errors.role = 'Invalid user role!';
    }
    
    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export default create;