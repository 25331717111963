import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { 
    Avatar,
    Button,
    FormControl, 
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from'@material-ui/core';
import { makeStyles } from'@material-ui/core/styles';

import { GET_ERRORS } from '../../../actions/types';
import { editUser } from '../../../actions/users';
import { ADMIN, AUTHOR, COLORS } from '../../../utils/constants';
import validateEditUser from '../../../utils/validation/users/edit';
import isEmpty from '../../../utils/isEmpty';

import Spinner from '../../../components/common/Spinner';
import Toast from '../../../components/common/Toast';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        
        '& form': {
            border: `1px solid ${COLORS.offWhite}`,
            borderRadius: theme.shape.borderRadius,
            marginTop: theme.spacing(5),
            padding: theme.spacing(5),
            width: '70%',

            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
                width: '90%'
            }
        }
    },

    avatar: {
        height: theme.spacing(50),
        width: theme.spacing(50)
    }
}));


const EditUser = ({ editUser, handleSetHeading, handleSetTitle }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const errorsState = useSelector(state => state.errors);
    const { msg, user } = useSelector(state => state.users);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [role, setRole] = useState('');
    const [bio, setBio] = useState('');
    const [image, setImage] = useState(null);
    const [imageSrc, setImageSrc] = useState('');
    const [loadingText, setLoadingText] = useState('');
    const [loading, setLoading] = useState(false);
    
    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const [errors, setErrors] = useState({});

    const toast = useRef();

    const roles = [ADMIN, AUTHOR];

    useEffect(() => {
        handleSetHeading('Edit User');
        handleSetTitle('Edit User');

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);
        setUsername(user.username);
        setRole(user.role);
        setBio(user.bio);
        setImageSrc(user.avatar);
    }, [user]);

    useEffect(() => {
        if (toastMessage) {
            toast.current.handleClick();
            dispatch({
                type: GET_ERRORS,
                payload: {}
            });
        }
    }, [dispatch, toastMessage]);

    useEffect(() => {
        if (!_.isEmpty(errorsState)) {
            setLoading(false);
            setErrors(errorsState);
        }
    }, [dispatch, errorsState]);

    useEffect(() => {
        if (!isEmpty(errors.msg)) {
            setToastType('error');
            setToastTitle('ERROR');
            setToastMessage(errors.msg);
            
        }
    }, [errors]);

    useEffect(() => {
        if (msg) {
            setLoading(false);
            setToastType('success');
            setToastTitle('SUCCESS');
            setToastMessage(msg);
            setTimeout(() => {
                navigate(-1);
            }, 5000);
        }
    }, [navigate, msg]);

    const handleSetImage = (e) => {
        setImage(e.target.files[0]);
        const reader = new FileReader();

        reader.onload = (() => {
            const image = reader.result; //Array Buffer
            setImageSrc(image);
        });
        reader.readAsDataURL(e.target.files[0]);
    };

    const removeImage = () => {
        setImageSrc('');
        setImage(null);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        setToastType('');
        setToastTitle('');
        setToastMessage('');
        
        const data = {
            firstName,
            lastName,
            username,
            email,
            role,
            bio
        };

        const { errors, isValid } = validateEditUser(data);

        if (!isValid) {
            return setErrors({ ...errors, msg: 'Invalid user data' });
        }

        setErrors({});
        setLoadingText('Updating User');
        setLoading(true);

        let author = new FormData();
        author.append('avatar', image);
        author.append('firstName', firstName);
        author.append('lastName', lastName);
        author.append('email', email);
        author.append('username', username);
        author.append('role', role);
        author.append('bio', bio);

        setErrors({});
        setLoading(true);
        editUser(author, user._id);
    };

    return (
        <>
            {loading && <Spinner text={loadingText} />}
            <Toast 
                ref={toast}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            <section className={classes.root}>
                {/* <Typography variant="h4">Create New User</Typography> */}
                <form onSubmit={onSubmit} noValidate>
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="First Name"
                                placeholder="Enter First Name"
                                helperText={errors.firstName}
                                fullWidth
                                required
                                error={errors.firstName ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="Last Name"
                                placeholder="Enter Last Name"
                                helperText={errors.lastName}
                                fullWidth
                                required
                                error={errors.lastName ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="Username"
                                placeholder="Enter username"
                                helperText={errors.user}
                                fullWidth
                                required
                                error={errors.user ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={email}
                                // onChange={(e) => setEmail(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="Email Address"
                                placeholder="Enter Email Address"
                                helperText={errors.email}
                                fullWidth
                                required
                                error={errors.email ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl fullWidth required>
                                <InputLabel id="user-role">User Role</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="user-role"
                                    value={role}
                                    // label="User Role"
                                    onChange={(e) => setRole(e.target.value)}
                                    helperText={errors.role}
                                    error={errors.role ? true : false}
                                >
                                    <MenuItem value="">SELECT USER ROLE</MenuItem>
                                    {roles.map((role, index) => (
                                        <MenuItem key={index} value={role} disabled={user.role === role}>{role}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={bio}
                                onChange={(e) => setBio(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="User Bio"
                                placeholder="Enter User Biography"
                                helperText={errors.bio}
                                fullWidth
                                multiline
                                minRows={2}
                                error={errors.bio  ? true : false}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <input
                                accept="image/*"
                                className={classes.input}
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                onChange={handleSetImage}
                                type="file"
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="outlined" component="span" className={classes.uploadButton} color="primary">
                                    Select Image
                                </Button>
                            </label> 
                        </Grid>
                        <Grid item xs={6}>
                            {imageSrc &&
                                <Button variant="outlined" component="span" className={classes.uploadButton} color="primary" onClick={removeImage}>
                                    Remove Image
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {imageSrc && <Avatar src={imageSrc} classes={{ root: classes.avatar }} />}
                            {/* <img src={imageSrc} alt={`${firstName} ${lastName}`} className={classes.ava} /> */}
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                className={classes.button}
                                variant="contained" 
                                color="primary"
                                type="submit"
                                size="large"
                                fullWidth
                            >
                                Update User
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </section>
        </>
    );
};

EditUser.propTypes = {
    editUser: PropTypes.func.isRequired,
    handleSetHeading: PropTypes.func.isRequired,
    handleSetTitle: PropTypes.func.isRequired
};

export default connect(undefined, { editUser })(EditUser);