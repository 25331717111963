import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { 
    Button, 
    Grid, 
    IconButton,
    InputAdornment,
    TextField, 
    Tooltip,
    Typography 
} from '@material-ui/core';
import { EyeOutline, EyeOffOutline } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import Spinner from '../../components/common/Spinner';
import Toast from '../../components/common/Toast';

import { login } from '../../actions/auth';
import validateLoginInput from '../../utils/validation/auth/login';
import isEmpty from '../../utils/isEmpty';
import { COLORS } from '../../utils/constants';
import { GET_ERRORS } from '../../actions/types';

import background from '../../assets/img/about.jpg';
import logo from '../../assets/img/icon-small.png';
import { DASHBOARD, DASHBOARD_HOME, FORGOT_PASSWORD } from '../../routes';

const useStyles = makeStyles(theme => ({
    root: {
        // paddingTop: theme.spacing(15),
        backgroundColor: COLORS.offWhite,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        overflowY: 'hidden'
    },
    
    gridContainer: {
        // margin: [[theme.spacing(15), 'auto']],
        borderRadius: theme.shape.borderRadius,
        height: '80vh',
        overflow: 'hidden',
        // maxHeight: '100%',
        width: '75%'
    },

    image: {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',

        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },

    formContainer: {
        backgroundColor: COLORS.white,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        padding: [[theme.spacing(8), theme.spacing(4)]],

        '& h5': {
            marginBottom: theme.spacing(5),
            textAlign: 'center'
        },
    },

    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(5),

        [theme.breakpoints.down('md')]: {
            display: 'none'
        },

        '& img': {
            display: 'block',
            width: '25%'
        },
    }
}));


const Login = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const errorsState = useSelector(state => state.errors);
    const { authenticated } = useSelector(state => state.auth);
    const { msg } = useSelector(state => state.posts);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    // const [loading, setLoading] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const { login } = props;

    const toast = useRef();

    useEffect(() => {
        if (authenticated) {
            navigate(`${DASHBOARD}${DASHBOARD_HOME}`, { replace: true });
        }
        return () => {
            dispatch({
                type: GET_ERRORS,
                payload: {}
            });
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!_.isEmpty(errorsState)) {
            setErrors(errorsState);
            setLoading(false);
        }
    }, [errorsState]);

    useEffect(() => {
        if (!isEmpty(errors.msg)) {
            setToastType('error');
            setToastTitle('ERROR');
            setToastMessage(errors.msg);
        }
    }, [errors]);

    useEffect(() => {
        if (msg) {
            setToastType('success');
            setToastTitle('SUCCESS');
            setToastMessage(msg);
            setLoading(false);
        }
    }, [msg]);

    useEffect(() => {
        if (toastMessage) {
            toast.current.handleClick();
        }
    }, [toastMessage]);

    // const handleSocialLogin = (user) => {
    //     const { _profile, _provider } = user;

    //     const data = {
    //         user: {
    //             email: _profile.email,
    //             provider: _provider
    //         },
    //         profile: {
    //             firstName: _profile.firstName,
    //             lastName: _profile.lastName,
    //             avatar: _profile.profilePicURL,
    //         }
    //     };
    //     props.socialLogin(data, navigate);
    // };
      
    // const handleSocialLoginFailure = (err) => {
    //     console.error(err);
    // };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        dispatch({
            type: GET_ERRORS,
            payload: {}
        });

        const user = {
            email, 
            password
        };

        const { errors, isValid } = validateLoginInput(user);

        if (!isValid) {
            return setErrors(errors);
        }

        setLoading(true);
        setErrors({});
        login(user, navigate);
    };

    return (
        <>
            <Toast 
                ref={toast}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            {loading && <Spinner />}
            <section className={classes.root}>
                <Grid container direction="row" className={classes.gridContainer}>
                    <Grid item xs={12} lg={6} className={classes.image}>
                        
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.formContainer}>
                        <form onSubmit={onSubmit} noValidate>
                            <div className={classes.imageContainer}>
                                <img src={logo} alt="Cyberkach Logo" />
                            </div>
                            <Typography variant="h5">LOG IN</Typography>
                            <Grid container direction="column" spacing={5}>
                                <Grid item xs={12}>
                                    <TextField 
                                        className={classes.input}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email"
                                        variant="outlined" 
                                        label="Email Address"
                                        placeholder="Enter Email"
                                        helperText={errors.email}
                                        fullWidth
                                        required
                                        error={errors.email ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        className={classes.input}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={showPassword ? 'text': 'password'}
                                        variant="outlined" 
                                        label="Password"
                                        placeholder="Enter Password"
                                        helperText={errors.password}
                                        fullWidth
                                        required
                                        error={errors.password  ? true : false}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={toggleShowPassword}
                                                    >
                                                        {showPassword ? 
                                                            <Tooltip title="Hide password" placement="bottom" arrow>
                                                                <EyeOffOutline />
                                                            </Tooltip>
                                                                : 
                                                            <Tooltip title="Show password" placement="bottom" arrow>
                                                                <EyeOutline />
                                                            </Tooltip>
                                                            }
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" component="p">Forgot your password? <Link to={FORGOT_PASSWORD}>Click here</Link> to recover it.</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button 
                                        className={classes.button}
                                        variant="contained" 
                                        color="primary"
                                        type="submit"
                                        fullWidth
                                    >
                                        Sign In
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>

                    {/* <SocialLoginButton
                        // className={classnames('button facebook-button', {
                        //     'disabled-button': submitting
                        // })}
                        provider="facebook"
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                    >
                        <span className="mdi mdi-facebook facebook"></span>&nbsp;&nbsp;Facebook
                    </SocialLoginButton>
                    <SocialLoginButton
                        // className={classnames('button google-button', {
                        //     'disabled-button': submitting
                        // })}
                        provider="google"
                        appId={process.env.REACT_APP_GOOGLE_APP_ID}
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                    >
                        <span className="mdi mdi-google google"></span>&nbsp;&nbsp;
                        <span className="letters">G</span>
                        <span className="letters">o</span>
                        <span className="letters">o</span>
                        <span className="letters">g</span>
                        <span className="letters">l</span>
                        <span className="letters">e</span>
                    </SocialLoginButton> */}
                </Grid>
            </section>
        </>
    );
};

Login.propTypes = {
    login: PropTypes.func.isRequired
};

export default connect(undefined, { login })(Login);