import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { 
    Button,
    FormControl, 
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from'@material-ui/core';
import { makeStyles } from'@material-ui/core/styles';

import { GET_ERRORS, SET_USER_MSG } from '../../../actions/types';
import { createUser } from '../../../actions/users';
import { ADMIN, AUTHOR, COLORS, EDITOR } from '../../../utils/constants';
import validateCreateUser from '../../../utils/validation/users/create';
import isEmpty from '../../../utils/isEmpty';

import Spinner from '../../../components/common/Spinner';
import Toast from '../../../components/common/Toast';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        
        '& form': {
            border: `1px solid ${COLORS.offWhite}`,
            borderRadius: theme.shape.borderRadius,
            marginTop: theme.spacing(5),
            padding: theme.spacing(5),
            width: '70%',

            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
                width: '90%'
            }
        }
    }
}));


const CreateUser = ({ createUser, handleSetHeading, handleSetTitle }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const errorsState = useSelector(state => state.errors);
    const { msg } = useSelector(state => state.users);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [bio, setBio] = useState('');
    const [loadingText, setLoadingText] = useState('');
    const [loading, setLoading] = useState(false);
    
    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const [errors, setErrors] = useState({});

    const toast = useRef();

    const roles = [ADMIN, AUTHOR, EDITOR];

    useEffect(() => {
        handleSetHeading('Create New User');
        handleSetTitle('Create New User');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (toastMessage) {
            toast.current.handleClick();
            setFirstName('');
            setLastName('');
            setEmail('');
            setRole('');
            setBio('');
            dispatch({
                type: GET_ERRORS,
                payload: {}
            });
        }
    }, [dispatch, toastMessage]);

    useEffect(() => {
        if (!_.isEmpty(errorsState)) {
            setLoading(false);
            setErrors(errorsState);
        }
    }, [dispatch, errorsState]);

    useEffect(() => {
        if (!isEmpty(errors.msg)) {
            setToastType('error');
            setToastTitle('ERROR');
            setToastMessage(errors.msg);
            
        }
    }, [errors]);

    useEffect(() => {
        if (msg) {
            setToastMessage(msg);
            setLoading(false);
            setToastType('success');
            setToastTitle('SUCCESS');
            dispatch({
                type: SET_USER_MSG,
                payload: null
            });
        }
    }, [dispatch, msg]);

    const onSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        setToastType('');
        setToastTitle('');
        setToastMessage('');
        
        const data = {
            firstName,
            lastName,
            email,
            role,
            bio
        };

        const { errors, isValid } = validateCreateUser(data);

        if (!isValid) {
            return setErrors({ ...errors, msg: 'Invalid user data' });
        }

        setErrors({});
        setLoadingText(`CREATING ${role} . . .`);
        setLoading(true);
        createUser(data);
    };

    return (
        <>
            {loading && <Spinner text={loadingText} />}
            <Toast 
                ref={toast}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            <section className={classes.root}>
                {/* <Typography variant="h4">Create New User</Typography> */}
                <form onSubmit={onSubmit} noValidate>
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="First Name"
                                placeholder="Enter First Name"
                                helperText={errors.firstName}
                                fullWidth
                                required
                                error={errors.firstName ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="Last Name"
                                placeholder="Enter Last Name"
                                helperText={errors.lastName}
                                fullWidth
                                required
                                error={errors.lastName ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="Email Address"
                                placeholder="Enter Email Address"
                                helperText={errors.email}
                                fullWidth
                                required
                                error={errors.email ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl fullWidth required>
                                <InputLabel id="user-role">User Role</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="user-role"
                                    value={role}
                                    // label="User Role"
                                    onChange={(e) => setRole(e.target.value)}
                                    helperText={errors.role}
                                    error={errors.role ? true : false}
                                >
                                    <MenuItem value="">SELECT USER ROLE</MenuItem>
                                    {roles.map((role, index) => (
                                        <MenuItem key={index} value={role} disabled={role === ADMIN}>{role}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField 
                                className={classes.input}
                                value={bio}
                                onChange={(e) => setBio(e.target.value)}
                                type="text"
                                variant="outlined" 
                                label="User Bio"
                                placeholder="Enter User Biography"
                                helperText={errors.bio}
                                fullWidth
                                multiline
                                minRows={2}
                                error={errors.bio  ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                className={classes.button}
                                variant="contained" 
                                color="primary"
                                type="submit"
                                size="large"
                                fullWidth
                            >
                                Create User
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </section>
        </>
    );
};

CreateUser.propTypes = {
    createUser: PropTypes.func.isRequired,
    handleSetHeading: PropTypes.func.isRequired,
    handleSetTitle: PropTypes.func.isRequired
};

export default connect(undefined, { createUser })(CreateUser);