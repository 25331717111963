import axios from 'axios';
import { batch } from 'react-redux';
import { ADDED_USER, EDITED_USER, SET_ADMINS, SET_AUTHORS, SET_EDITORS, TOGGLE_DISABLED_USER } from './types';

import handleError from '../utils/handleError';

const API = '/users';

export const getAllUsers = (data) => async (dispatch) => {
    try {
        const res = await axios.get(API, data);
        const { admins, authors, editors } = res.data.data;
        return batch(() => {
            dispatch({
                type: SET_ADMINS,
                payload: admins
            });
            dispatch({
                type: SET_AUTHORS,
                payload: authors
            });
            dispatch({
                type: SET_EDITORS,
                payload: editors
            });
        });
    } catch (err) {
        console.error(err);
        // return handleError(err, dispatch);
    }
};

export const createUser = (data) => async (dispatch) => {
    try {
        const res = await axios.post(API, data);
        return dispatch({
            type: ADDED_USER,
            payload: { user: res.data.data, msg: res.data.msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const editUser = (user, userId) => async (dispatch) => {
    try {
        // const res = await axios.put(`${API}/edit/${user._id}`, user);
        const res = await axios({
            method: 'put',
            url: `${API}/${userId}`,
            data: user,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return dispatch({
            type: EDITED_USER,
            payload: { user: res.data.data, msg: res.data.msg }
        });
    } catch (err) {
        console.error(err);
        // return handleError(err, dispatch);
    }
};

export const toggleDisableUser = (userId) => async (dispatch) => {
    try {
        const res = await axios.get(`${API}/disable/${userId}`);
        return dispatch({
            type: TOGGLE_DISABLED_USER,
            payload: { user: res.data.data, msg: res.data.msg }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};