import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Grid, TextField } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Spinner from '../../../components/common/Spinner';
import Toast from '../../../components/common/Toast';

import { createPodcast } from '../../../actions/podcasts';
import isEmpty from '../../../utils/isEmpty';
import { GET_ERRORS, SET_PODCAST_MSG } from '../../../actions/types';
import { ADMIN } from '../../../utils/constants';

import validateCreatePodcast from '../../../utils/validation/podcasts/create';


const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
        overflow: 'hidden',
        paddingBottom: theme.spacing(5),

        '& form': {
            marginTop: theme.spacing(2),
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5)
        },
    }
}));

const CreatePodcast = (props) => {
	const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const errorsState = useSelector(state => state.errors);
    const { msg } = useSelector(state => state.podcasts);
    const user = useSelector(state => state.auth.user);

    const [url, setUrl] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [toastType, setToastType] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [toastDuration, setToastDuration] = useState(0);

    const { handleSetHeading, handleSetTitle } = props;

    const toast = useRef();

	useEffect(() => {
        if (user.role !== ADMIN) {
            return navigate(-1);
        }
		handleSetTitle('Create Podcast');
		handleSetHeading('Create Podcast');

        return () => {
            dispatch({
                type: SET_PODCAST_MSG,
                payload: null
            });
            dispatch({
                type: GET_ERRORS,
                payload: {}
            })
        };
		// eslint-disable-next-line
	}, []);

    useEffect(() => {
        if (!_.isEmpty(errorsState)) {
            setErrors(errorsState);
            setLoading(false);
        }
    }, [errorsState]);

    useEffect(() => {
        if (!isEmpty(errors.msg)) {
            setToastType('error');
            setToastTitle('ERROR');
            setToastMessage(errors.msg);
        }
    }, [errors]);

    useEffect(() => {
        if (msg) {
            setToastType('success');
            setToastTitle('SUCCESS');
            setToastMessage(msg);
            setToastDuration(3000);
            setLoading(false);
            setTimeout(() => {
                navigate(-1);
            }, 3000);
        }
    }, [navigate, msg]);

    useEffect(() => {
        if (toastMessage) {
            toast.current.handleClick();
            setTimeout(() => {
                dispatch({
                    type: SET_PODCAST_MSG,
                    payload: null
                });
            }, toastDuration);
        }
    }, [dispatch, toastDuration, toastMessage]);

    const onSubmit = (e) => {
        e.preventDefault();
        setErrors({});

        const { errors, isValid } = validateCreatePodcast({ url, title, description });

        if (!isValid) {
            return setErrors(errors);
        }

        setErrors({});
        setLoading(true);

        props.createPodcast({ url, title, description });
    };

	return (
        <>
            <Toast 
                ref={toast}
                title={toastTitle}
                duration={5000}
                msg={toastMessage}
                type={toastType}
            />
            {loading && <Spinner />}
            <section className={classes.root}>
                <form onSubmit={onSubmit} noValidate>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <TextField 
                                className={classes.input}
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                type="text"
                                variant="outlined" 
                                placeholder="Enter podcast URL"
                                helperText={errors.url}
                                fullWidth
                                required
                                error={errors.url ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                className={classes.input}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                type="text"
                                variant="outlined" 
                                placeholder="Enter podcast title"
                                helperText={errors.title}
                                fullWidth
                                required
                                error={errors.title ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                className={classes.input}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                type="text"
                                variant="outlined" 
                                placeholder="Enter podcast summary"
                                helperText={errors.description}
                                fullWidth
                                required
                                error={errors.description ? true : false}
                                multiline
                                maxRows={2}
                                minRows={2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                variant="contained" 
                                color="primary"
                                type="submit"
                            >
                                Save Podcast
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </section>
        </>
	);
}

CreatePodcast.propTypes = {
    createPodcast: PropTypes.func.isRequired
};

export default connect(undefined, { createPodcast })(CreatePodcast);