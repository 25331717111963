export const BLOG = '/blog';
export const PODCASTS = '/podcasts';
export const CONTACT_US = '/contactUs';
export const PRIVACY_POLICY = '/privacyPolicy';

export const LOGIN = '/xyz/login';
export const VERIFY_OTP = '/auth/verifyOtp';
export const FORGOT_PASSWORD = '/auth/forgotPassword';
export const RESET_PASSWORD = '/auth/resetPassword/:token';

export const DASHBOARD = '/dashboard';
export const DASHBOARD_HOME = '/home';
export const POSTS = '/posts';
export const CREATE_POST = `${POSTS}/createPost`;
export const CREATE_PODCAST = `${POSTS}/createPodcast`;
export const EDIT_PODCAST = `${POSTS}/editPodcast`;
export const EDIT_POST = `${POSTS}/editPost`;
export const USERS = `/users`;
export const CREATE_USER = `${USERS}/create`;
export const EDIT_USER = `${USERS}/edit`;
export const PROFILE = `/user/profile`;